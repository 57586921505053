import { $BTSTracker, BTSTracker } from "./BTSTracker";
const gsmBands = [
  { band: 'GSM 450', earfcn: [259, 293] }, { band: 'GSM 480', earfcn: [306, 340] }, { band: 'GSM 750', earfcn: [438, 511] }, { band: 'GSM 850', earfcn: [128, 251] },
  { band: 'P-GSM', earfcn: [1, 124] }, { band: 'E-GSM', earfcn: [975, 1023] }, { band: 'GSM-R', earfcn: [955, 1023] }, { band: 'DCS 1800', earfcn: [512, 885] },
  { band: 'PCS 1900', earfcn: [512, 810] }
];
const umtsBands = [
  { band: 'B1', earfcn: [10562, 10838] }, { band: 'B2', earfcn: [9662, 9938] }, { band: 'B3', earfcn: [1162, 1513] },
  { band: 'B4', earfcn: [1537, 1738] }, { band: 'B5', earfcn: [4357, 4458] }, { band: 'B6', earfcn: [4387, 4413] }, { band: 'B7', earfcn: [2237, 2563] },
  { band: 'B8', earfcn: [2937, 3088] },
  { band: 'B9', earfcn: [9237, 9387] },
  { band: 'B10', earfcn: [3112, 3388] },
  { band: 'B11', earfcn: [3712, 3787] },
  { band: 'B12', earfcn: [3842, 3903] },
  { band: 'B13', earfcn: [4017, 4043] },
  { band: 'B14', earfcn: [4117, 4143] },
  { band: 'B19', earfcn: [712, 763] },
  { band: 'B20', earfcn: [4512, 4638] },
  { band: 'B21', earfcn: [862, 912] },
  { band: 'B22', earfcn: [4662, 5038] },
  { band: 'B25', earfcn: [5112, 5413] },
  { band: 'B26', earfcn: [5762, 5913] },
  { band: 'B32', earfcn: [6617, 6813] }
];
const lteBands = [
  { band: '1', up_fr: [1920, 1980], up_earfcn: [18000, 18599], dl_fr: [2110, 2170], dl_earfcn: [0, 599], duplex: 'FDD' },
  { band: '2', up_fr: [1850, 1910], up_earfcn: [18600, 19199], dl_fr: [1930, 1990], dl_earfcn: [600, 1199], duplex: 'FDD' },
  { band: '3', up_fr: [1710, 1785], up_earfcn: [19200, 19949], dl_fr: [1805, 1880], dl_earfcn: [1200, 1949], duplex: 'FDD' },
  { band: '4', up_fr: [1710, 1755], up_earfcn: [19950, 20399], dl_fr: [2110, 2155], dl_earfcn: [1950, 2399], duplex: 'FDD' },
  { band: '5', up_fr: [824, 849], up_earfcn: [20400, 20649], dl_fr: [869, 894], dl_earfcn: [2400, 2649], duplex: 'FDD' },
  { band: '7', up_fr: [2500, 2570], up_earfcn: [20750, 21449], dl_fr: [2620, 2690], dl_earfcn: [2750, 3449], duplex: 'FDD' },
  { band: '8', up_fr: [880, 915], up_earfcn: [21450, 21799], dl_fr: [925, 960], dl_earfcn: [3450, 3799], duplex: 'FDD' },
  { band: '9', up_fr: [1749.9, 1784.9], up_earfcn: [21800, 22149], dl_fr: [1844.9, 1879.9], dl_earfcn: [3800, 4149], duplex: 'FDD' },
  { band: '10', up_fr: [1710, 1770], up_earfcn: [22150, 22749], dl_fr: [2110, 2170], dl_earfcn: [4150, 4749], duplex: 'FDD' },
  { band: '11', up_fr: [1427.9, 1447.9], up_earfcn: [22750, 22949], dl_fr: [1475.9, 1495.9], dl_earfcn: [4750, 4949], duplex: 'FDD' },
  { band: '12', up_fr: [699, 716], up_earfcn: [23010, 23179], dl_fr: [729, 746], dl_earfcn: [5010, 5179], duplex: 'FDD' },
  { band: '13', up_fr: [777, 787], up_earfcn: [23180, 23279], dl_fr: [746, 756], dl_earfcn: [5180, 5279], duplex: 'FDD' },
  { band: '14', up_fr: [788, 798], up_earfcn: [23280, 23379], dl_fr: [758, 768], dl_earfcn: [5280, 5379], duplex: 'FDD' },
  { band: '17', up_fr: [704, 716], up_earfcn: [23730, 23849], dl_fr: [734, 746], dl_earfcn: [5730, 5849], duplex: 'FDD' },
  { band: '18', up_fr: [815, 830], up_earfcn: [23850, 23999], dl_fr: [860, 875], dl_earfcn: [5850, 5999], duplex: 'FDD' },
  { band: '19', up_fr: [830, 845], up_earfcn: [24000, 24149], dl_fr: [875, 890], dl_earfcn: [6000, 6149], duplex: 'FDD' },
  { band: '20', up_fr: [832, 862], up_earfcn: [24150, 24449], dl_fr: [791, 821], dl_earfcn: [6150, 6449], duplex: 'FDD' },
  { band: '21', up_fr: [1447.9, 1462.9], up_earfcn: [24450, 24599], dl_fr: [1495.9, 1510.9], dl_earfcn: [6450, 6599], duplex: 'FDD' },
  { band: '22', up_fr: [3410, 3490], up_earfcn: [24600, 25399], dl_fr: [3510, 3590], dl_earfcn: [6600, 7399], duplex: 'FDD' },
  { band: '24', up_fr: [1626.5, 1660.5], up_earfcn: [25700, 26039], dl_fr: [1525, 1559], dl_earfcn: [7700, 8039], duplex: 'FDD' },
  { band: '25', up_fr: [1850, 1915], up_earfcn: [26040, 26689], dl_fr: [1930, 1995], dl_earfcn: [8040, 8689], duplex: 'FDD' },
  { band: '26', up_fr: [814, 849], up_earfcn: [26690, 27039], dl_fr: [859, 894], dl_earfcn: [8690, 9039], duplex: 'FDD' },
  { band: '27', up_fr: [807, 824], up_earfcn: [27040, 27209], dl_fr: [852, 869], dl_earfcn: [9040, 9209], duplex: 'FDD' },
  { band: '28', up_fr: [703, 748], up_earfcn: [27210, 27659], dl_fr: [758, 803], dl_earfcn: [9210, 9659], duplex: 'FDD' },
  { band: '29', up_fr: [null, null], up_earfcn: [null, null], dl_fr: [717, 728], dl_earfcn: [9660, 9769], duplex: 'SDL' },
  { band: '30', up_fr: [2305, 2315], up_earfcn: [27660, 27759], dl_fr: [2350, 2360], dl_earfcn: [9770, 9869], duplex: 'FDD' },
  { band: '31', up_fr: [452.5, 457.5], up_earfcn: [27760, 27809], dl_fr: [462.5, 467.5], dl_earfcn: [9870, 9919], duplex: 'FDD' },
  { band: '32', up_fr: [null, null], up_earfcn: [null, null], dl_fr: [1452, 1496], dl_earfcn: [9920, 10359], duplex: 'SDL' },
  { band: '33', up_fr: [null, null], up_earfcn: [null, null], dl_fr: [1900, 1920], dl_earfcn: [36000, 36199], duplex: 'TDD' },
  { band: '34', up_fr: [null, null], up_earfcn: [null, null], dl_fr: [2010, 2025], dl_earfcn: [36200, 36349], duplex: 'TDD' },
  { band: '35', up_fr: [null, null], up_earfcn: [null, null], dl_fr: [1850, 1910], dl_earfcn: [36350, 36949], duplex: 'TDD' },
  { band: '36', up_fr: [null, null], up_earfcn: [null, null], dl_fr: [1930, 1990], dl_earfcn: [36950, 37549], duplex: 'TDD' },
  { band: '37', up_fr: [null, null], up_earfcn: [null, null], dl_fr: [1910, 1930], dl_earfcn: [37550, 37749], duplex: 'TDD' },
  { band: '38', up_fr: [null, null], up_earfcn: [null, null], dl_fr: [2570, 2620], dl_earfcn: [37750, 38249], duplex: 'TDD' },
  { band: '39', up_fr: [null, null], up_earfcn: [null, null], dl_fr: [1880, 1920], dl_earfcn: [38250, 38649], duplex: 'TDD' },
  { band: '40', up_fr: [null, null], up_earfcn: [null, null], dl_fr: [2300, 2400], dl_earfcn: [38650, 39649], duplex: 'TDD' },
  { band: '41', up_fr: [null, null], up_earfcn: [null, null], dl_fr: [2496, 2690], dl_earfcn: [39650, 41589], duplex: 'TDD' },
  { band: '42', up_fr: [null, null], up_earfcn: [null, null], dl_fr: [3400, 3600], dl_earfcn: [41590, 43589], duplex: 'TDD' },
  { band: '43', up_fr: [null, null], up_earfcn: [null, null], dl_fr: [3600, 3800], dl_earfcn: [43590, 45589], duplex: 'TDD' },
  { band: '44', up_fr: [null, null], up_earfcn: [null, null], dl_fr: [703, 803], dl_earfcn: [45590, 46589], duplex: 'TDD' },
  { band: '45', up_fr: [null, null], up_earfcn: [null, null], dl_fr: [1447, 1467], dl_earfcn: [46590, 46789], duplex: 'TDD' },
  { band: '46', up_fr: [null, null], up_earfcn: [null, null], dl_fr: [5150, 5925], dl_earfcn: [46790, 54539], duplex: 'TDD' },
  { band: '47', up_fr: [null, null], up_earfcn: [null, null], dl_fr: [5855, 5925], dl_earfcn: [54540, 55239], duplex: 'TDD' },
  { band: '48', up_fr: [null, null], up_earfcn: [null, null], dl_fr: [3550, 3700], dl_earfcn: [55240, 56739], duplex: 'TDD' },
  { band: '49', up_fr: [null, null], up_earfcn: [null, null], dl_fr: [3550, 3700], dl_earfcn: [56740, 58239], duplex: 'TDD' },
  { band: '50', up_fr: [null, null], up_earfcn: [null, null], dl_fr: [1432, 1517], dl_earfcn: [58240, 59089], duplex: 'TDD' },
  { band: '51', up_fr: [null, null], up_earfcn: [null, null], dl_fr: [1427, 1432], dl_earfcn: [59090, 59139], duplex: 'TDD' },
  { band: '52', up_fr: [null, null], up_earfcn: [null, null], dl_fr: [3300, 3400], dl_earfcn: [59140, 60139], duplex: 'TDD' },
  { band: '53', up_fr: [null, null], up_earfcn: [null, null], dl_fr: [2483.5, 2495], dl_earfcn: [60140, 60254], duplex: 'TDD' },
  { band: '65', up_fr: [1920, 2010], up_earfcn: [131072, 131971], dl_fr: [2110, 2200], dl_earfcn: [65536, 66435], duplex: 'FDD' },
  { band: '66', up_fr: [1710, 1780], up_earfcn: [131972, 132671], dl_fr: [2110, 2200], dl_earfcn: [66436, 67335], duplex: 'FDD' },
  { band: '67', up_fr: [null, null], up_earfcn: [null, null], dl_fr: [738, 758], dl_earfcn: [67336, 67535], duplex: 'SDL' },
  { band: '68', up_fr: [698, 728], up_earfcn: [132672, 132971], dl_fr: [753, 783], dl_earfcn: [67536, 67835], duplex: 'FDD' },
  { band: '69', up_fr: [null, null], up_earfcn: [null, null], dl_fr: [2570, 2620], dl_earfcn: [67836, 68335], duplex: 'SDL' },
  { band: '70', up_fr: [1695, 1710], up_earfcn: [132972, 133121], dl_fr: [1995, 2020], dl_earfcn: [68336, 68585], duplex: 'FDD' },
  { band: '71', up_fr: [663, 698], up_earfcn: [133122, 133471], dl_fr: [617, 652], dl_earfcn: [68586, 68935], duplex: 'FDD' },
  { band: '72', up_fr: [451, 456], up_earfcn: [133472, 133521], dl_fr: [461, 466], dl_earfcn: [68936, 68985], duplex: 'FDD' },
  { band: '73', up_fr: [450, 455], up_earfcn: [133522, 133571], dl_fr: [460, 465], dl_earfcn: [68986, 69035], duplex: 'FDD' },
  { band: '74', up_fr: [1427, 1470], up_earfcn: [133572, 134001], dl_fr: [1475, 1518], dl_earfcn: [69036, 69465], duplex: 'FDD' },
  { band: '75', up_fr: [null, null], up_earfcn: [null, null], dl_fr: [1432, 1517], dl_earfcn: [69466, 70315], duplex: 'SDL' },
  { band: '76', up_fr: [null, null], up_earfcn: [null, null], dl_fr: [1427, 1432], dl_earfcn: [70316, 70365], duplex: 'SDL' },
  { band: '85', up_fr: [698, 716], up_earfcn: [134002, 134181], dl_fr: [728, 746], dl_earfcn: [70366, 70545], duplex: 'FDD' },
  { band: '87', up_fr: [410, 415], up_earfcn: [134182, 134231], dl_fr: [420, 425], dl_earfcn: [70546, 70595], duplex: 'FDD' },
  { band: '88', up_fr: [412, 417], up_earfcn: [134232, 134281], dl_fr: [422, 427], dl_earfcn: [70596, 70645], duplex: 'FDD' }
];


export interface IScanResult {
  ScanNumber: number;
}

export class OperatoriTel {
  name = "";
  img = "";
  constructor(name: string, img: string) {
    this.name = name;
    this.img = img;
  }
}

export class frequenze {
  download = 0;
  constructor(download: number) {
    this.download = download;
  }
}

interface AvailableCell {
  cellCode: string;
  band: string;
  arfcn: number;
  power: number;
  operatore: string;
  img: string;
  down_frequency: number;
}

export class ScanResultH implements IScanResult {
  location: number[] = [];
  ScanNumber = 0;
  Technology = "";
  LastPart = 0;
  time: Date | undefined;
  Cells: AvailableCell[] = [];

  constructor(ScanNumber: number, Technology: string, Part: number, location: number[]) {
    this.ScanNumber = ScanNumber;
    if (Technology.indexOf("L") >= 0) {
      this.Technology = "LTE";
    } else if (Technology.indexOf("U") >= 0) {
      this.Technology = "UMTS";
    } else if (Technology.indexOf("G") >= 0) {
      this.Technology = "GSM";
    }
    this.LastPart = Part;
    this.location = location;
  }

  addCells(cell: string, band: string, freq: number, power: number, operator: OperatoriTel, down: number) {
    this.Cells.push(
      {
        cellCode: cell,
        band: band,
        arfcn: freq,
        power: power,
        operatore: operator.name,
        img: operator.img,
        down_frequency: (Number)(down.toFixed(1)),
      }
    );
  }

  getString() {
    let s = "";
    let i = 0;
    for (i = 0; i < this.Cells.length; i++) {
      s = s + this.Cells[i] + ";";
    }
    return s;
  }
}

export class ScanResultS implements IScanResult {
  ScanNumber = 0;
  Technology = "";
  operatore: OperatoriTel;
  constructor(ScanNumber: number, Technology: string, operatore: OperatoriTel) {
    this.ScanNumber = ScanNumber;
    this.Technology = Technology;
    this.operatore = operatore;
  }
}

export class ScanResultS_5GNSA extends ScanResultS {
  ScellID = "";
  frequencyBand = "";
  rsrp = 0;
  lte: ScanResultS_lte;
  constructor(ScanNumber: number, Technology: string,
    ScellID: string,
    frequencyBand: string,
    rsrq: string,
    lte: ScanResultS_lte
  ) {
    super(ScanNumber, Technology, lte.operatore);
    this.ScellID = ScellID;
    this.frequencyBand = frequencyBand;
    this.rsrp = Number(rsrq) / 10;
    this.lte = lte;
  }
}

export class ScanResultS_5GSA extends ScanResultS {
  OperationMode = "";
  mcc_mnc = "";
  ScellID = "";
  frequencyBand = "";
  rsrp = 0;
  cellcode = "";
  frequency: frequenze;
  constructor(ScanNumber: number, Technology: string,
    OperationMode: string,
    mcc_mnc: string,
    ScellID: string,
    frequencyBand: string,
    rsrq: string,
    operatore: OperatoriTel,
    frequency: frequenze
  ) {
    super(ScanNumber, Technology, operatore);
    this.OperationMode = OperationMode;
    this.mcc_mnc = mcc_mnc;
    this.ScellID = ScellID;
    this.frequencyBand = frequencyBand;
    this.rsrp = Number(rsrq) / 10;
    this.frequency = frequency;
  }
  setCellCode() {
    const value = Number(this.ScellID);
    const value1 = Math.floor(value / 256);
    const value2 = value % 256;
    this.cellcode = this.mcc_mnc + "-" + value1 + "-" + value2;
  }
}

export class ScanResultS_wcdma extends ScanResultS {
  OperationMode = "";
  mcc_mnc = "";
  ScellID = "";
  tac = "";
  frequencyBand = "";
  cellcode = "";
  value = 0; value1 = 0; value2 = 0;
  cellcodeS = "";
  frequency = "";
  constructor(ScanNumber: number, Technology: string,
    OperationMode: string,
    mcc_mnc: string,
    tac: string,
    ScellID: string,
    frequencyBand: string,
    operatore: OperatoriTel,
    frequency: string
  ) {
    super(ScanNumber, Technology, operatore);
    this.OperationMode = OperationMode;
    this.mcc_mnc = mcc_mnc;
    this.tac = tac;
    this.ScellID = ScellID;
    this.frequencyBand = frequencyBand;
    this.frequency = frequency;
  }
  setCellCode() {
    const value = Number(this.ScellID);
    const value1 = Math.floor(value / 256);
    const value2 = value % 256;
    this.cellcode = this.mcc_mnc + "-" + value1 + "-" + value2;
  }
}
export class ScanResultS_lte extends ScanResultS {
  OperationMode = "";
  mcc_mnc = "";
  ScellID = "";
  tac = "";
  frequencyBand = "";
  rsrp = 0;
  cellcode = "";
  value = 0; value1 = 0; value2 = 0;
  cellcodeS = "";
  frequency: frequenze;
  constructor(ScanNumber: number, Technology: string,
    OperationMode: string,
    mcc_mnc: string,
    tac: string,
    ScellID: string,
    frequencyBand: string,
    rsrp: string,
    operatore: OperatoriTel,
    frequency: frequenze
  ) {
    super(ScanNumber, Technology, operatore);
    this.OperationMode = OperationMode;
    this.mcc_mnc = mcc_mnc;
    this.tac = tac;
    this.ScellID = ScellID;
    this.frequencyBand = frequencyBand;
    this.rsrp = Number(rsrp) / 10;
    this.frequency = frequency;
  }
  setCellCode() {
    const value = Number(this.ScellID);
    const value1 = Math.floor(value / 256);
    const value2 = value % 256;
    this.cellcode = this.mcc_mnc + "-" + value1 + "-" + value2;
  }
  setCellCodeSecond() {
    if (this.mcc_mnc.indexOf("222-88") >= 0 || this.mcc_mnc.indexOf("222-99") >= 0 || this.mcc_mnc.indexOf("222-50") >= 0) {
      const num = parseInt(this.tac, 16);
      this.cellcodeS = " | " + this.mcc_mnc + "-" + num + "-" + this.ScellID;
    }
  }
}

export class ScanResultS_noService extends ScanResultS {
  OperationMode = "";
  constructor(ScanNumber: number, Technology: string, OperationMode: string,
    operatore: OperatoriTel
  ) {
    super(ScanNumber, Technology, operatore);
    this.OperationMode = OperationMode;
  }
}

export class ScanGPS implements IScanResult {
  ScanNumber = 0;
  latitude = 0
  longitude = 0;
  altitude = "";
  constructor(ScanNumber: number, Altitude: string) {
    this.ScanNumber = ScanNumber;
    this.altitude = Altitude;
  }
  setConversion(value: string, ind: string, type: number) {
    let deg = "", min = "";
    let m1 = -1, m2 = -1;
    let i = 0;
    for (i = 0; i < value.length; i++) {
      if (value.charAt(i) == '.') {
        m1 = i - 2;
        m2 = i - 1;
        min = min + value.charAt(m1) + value.charAt(m2) + value.charAt(i);
      }
      else if (m1 != -1) {
        min = min + value.charAt(i);
      }
    }
    for (i = 0; i < m1; i++) {
      deg = deg + value.charAt(i);
    }
    const degrees = Number(deg);
    const minutes = Number(min);
    let dec = degrees + (minutes / 60);
    if (ind.indexOf("S") >= 0 || ind.indexOf("W") >= 0) {
      dec = -1 * dec;
    }
    if (type == 1) {
      this.latitude = dec;
    } else {
      this.longitude = dec;
    }
  }
}

export class ScanResult {
  static parseFromAtCommand(atComm: string, surveyNum: number, location: number[]): IScanResult | null | undefined { // per moduli B,C,D
    if (atComm == null || atComm.length < 5) {
      return null;
    }
    if (atComm.indexOf("^NETSCAN") >= 0) {
      return ScanResult.parseHuaAtCommand(atComm, surveyNum, location);
    }
    else if (atComm.indexOf("+CPSI") >= 0) {
      return ScanResult.parseSimAtCommand(atComm, surveyNum, location);
    }
    else if (atComm.indexOf("+CGPSINFO") >= 0) {
      return ScanResult.parseGPSCommand(atComm, surveyNum);
    }
    return null;
  }

  static parseAtCommandHauwei(atComm: string, surveyNum: number, part: number, type: string, location: number[]) { // per moduli 1,2,3
    if (atComm.indexOf("ERROR") >= 0) {
      return null;
    }
    const cell = new ScanResultH(surveyNum, type, part, location);
    atComm = atComm.replaceAll("^NETSCAN: ", ";");
    const net = atComm.split(";");
    let i = 0;
    for (i = 1; i < net.length; i++) {
      const info = net[i].split(",");
      if (info[4].length > 0 && info[5].length > 0 && info[3].length > 0 && info[8].length > 0) {
        let v1, v2;
        if (type.indexOf("L") >= 0 || type.indexOf("U") >= 0) {
          v1 = Math.floor(parseInt(info[8], 16) / 256);
          v2 = (Number)(parseInt(info[8], 16) % 256);
        } else if (type.indexOf("G") >= 0) {
          v1 = parseInt(info[3], 16);
          v2 = parseInt(info[8], 16);
        }
        const c1 = ScanResult.cellH(info[4], info[5], (String)(v1), (String)(v2));
        let band;
        if (type.indexOf("L") >= 0) {
          band = this.getBand(1, type, (String)(info[0]));
        } else {
          band = this.getBand(1, type, info[9]);
        }
        const mcc_mnc = info[4] + "-" + info[5];
        const operator = this.getOperatore(mcc_mnc);
        const frequency = this.getFrequency(type, (Number)(info[0]));
        cell.addCells(c1, band, (Number)(info[0]), (Number)(info[7]), operator, frequency.download);
      }
    }
    return cell;
  }

  static parseAtCommandH2(atComm: string, surveyNum: number, part: number, type: string, location: number[]) { // per moduli 4,5
    if (atComm.indexOf("ERROR") >= 0) {
      return null;
    }
    if (atComm.indexOf("END") >= 0) {
      return null;
    }
    const cell = new ScanResultH(surveyNum, type, part, location);
    let resp = atComm.replaceAll('"', "");
    resp = resp.replaceAll("\r", "");
    if (resp.length > 0) {
      let command: any;
      if (resp.indexOf("+AOPS") >= 0) {
        resp = resp.replace("+", ";+");
      }
      if (resp.indexOf(";") >= 0) {
        command = resp.split(";");
      }
      for (let x = 0; x < command.length; x++) {
        if (command[x].length > 0) {
          if (command[x].indexOf("+AOPS:") >= 0) {
            command[x] = command[x].replace("+AOPS:", "");
            $BTSTracker.value.aops = command[x].split(",");
          } else {
            const cellAvailable = command[x].split(",");
            for (let i = 0; i < cellAvailable.length; i++) {
              if (cellAvailable[i].indexOf(":") >= 0) {
                const value = cellAvailable[i].split(":");
                cellAvailable[i] = value[1];
              }
            }
            const mcc = $BTSTracker.value.aops[2].charAt(0) + $BTSTracker.value.aops[2].charAt(1) + $BTSTracker.value.aops[2].charAt(2);
            let mnc = "";
            for (let y = 0; y < $BTSTracker.value.aops[2].length; y++) {
              if (y > 2) {
                mnc = mnc + $BTSTracker.value.aops[2].charAt(y);
              }
            }
            if (mnc.endsWith(' ')) {
              mnc = mnc.slice(0, -1);
            }
            let v1, v2;
            if (cellAvailable.length == 9) {
              if (type.indexOf("U") >= 0) {
                v1 = cellAvailable[5];
                v2 = (Number)(parseInt(cellAvailable[7]) % 65536);
              } else if (type.indexOf("L") >= 0) {
                v1 = Math.floor(parseInt(cellAvailable[7]) / 256);
                v2 = (Number)(parseInt(cellAvailable[7]) % 256);
              }
            } else if (cellAvailable.length == 10) {
              if (type.indexOf("G") >= 0) { // gsm
                v1 = cellAvailable[5];
                v2 = cellAvailable[8];
              }
            } else {
              return null;
            }
            const c = ScanResult.cellH(mcc, mnc, (String)(v1), (String)(v2));
            for (let x = 0; x < c.length; x++) {
              if (c.charAt(x) < '0' || c.charAt(x) > '9') {
                if (c.charAt(x) != '-') {
                  return null;
                }
              }
            }
            const band = this.getBand(3, type, (String)(cellAvailable[2]));
            const mcc_mnc = mcc + "-" + mnc;
            const operator = this.getOperatore(mcc_mnc);
            const frequency = this.getFrequency(type, (Number)(cellAvailable[2]));
            cell.addCells(c, band, (Number)(cellAvailable[2]), (Number)(cellAvailable[3]), operator, frequency.download);
          }
        }

      }
      // se è l'intestazione per cambio operatore
    }
    return cell;
  }

  private static getFrequency(type: string, arfcn: number): frequenze {
    let download = 0;
    if (type.indexOf("G") >= 0) {
      if (arfcn >= 259 && arfcn <= 293) {
        download = 450.6 + 0.2 * (arfcn - 259);
      } else if (arfcn >= 306 && arfcn <= 340) {
        download = 479 + 0.2 * (arfcn - 306);
      } else if (arfcn >= 438 && arfcn <= 511) {
        download = 747.2 + 0.2 * (arfcn - 438);
      } else if (arfcn >= 128 && arfcn <= 251) {
        download = 824.2 + 0.2 * (arfcn - 128);
      } else if (arfcn >= 1 && arfcn <= 124) {
        download = 890 + 0.2 * arfcn;
      } else if (arfcn >= 975 && arfcn <= 1023) {
        download = 890 + 0.2 * (arfcn - 1024);
      } else if (arfcn >= 512 && arfcn <= 885) {
        download = 1718 + 0.2 * (arfcn - 512);
      }
    }
    if (type.indexOf("L") >= 0) {
      const myBand = lteBands.find(o => o.dl_earfcn && arfcn >= o.dl_earfcn[0] && arfcn <= o.dl_earfcn[1]);
      if (myBand) {
        download = myBand.dl_fr[0] + 0.1 * (arfcn - myBand.dl_earfcn[0]);
      }
      else {
        download = 0;
      }
    }
    let FDL_offset, FUL_offset, diff, Nul;
    if (type.indexOf("U") >= 0) {
      if (((arfcn >= 10562) && (arfcn <= 10838))) {
        FDL_offset = 0;
        FUL_offset = 0;
        diff = arfcn - 10562;
        Nul = 9612 + diff;
      }
      else if (((arfcn >= 9662) && (arfcn <= 9938))) {
        FDL_offset = 0;
        FUL_offset = 0;
        diff = arfcn - 9662;
        Nul = 9262 + diff;
      }
      else if (((arfcn >= 1162) && (arfcn <= 1513))) {
        FDL_offset = 1575;
        FUL_offset = 1525;
        diff = arfcn - 1162;
        Nul = 937 + diff;
      }
      else if (((arfcn >= 1537) && (arfcn <= 1738))) {
        FDL_offset = 1805;
        FUL_offset = 1450;
        diff = arfcn - 1537;
        Nul = 1312 + diff;
      }
      else if (((arfcn >= 4357) && (arfcn <= 4458))) {
        FDL_offset = 0;
        FUL_offset = 0;
        diff = arfcn - 4357;
        Nul = 4132 + diff;
      }
      else if (((arfcn >= 4387) && (arfcn <= 4413))) {
        FDL_offset = 0;
        FUL_offset = 0;
        diff = arfcn - 4387;
        Nul = 4162 + diff;
      }
      else if (((arfcn >= 2237) && (arfcn <= 2563))) {
        FDL_offset = 2175;
        FUL_offset = 2100;
        diff = arfcn - 2237;
        Nul = 2012 + diff;
      }
      else if (((arfcn >= 2937) && (arfcn <= 3088))) {
        FDL_offset = 340;
        FUL_offset = 340;
        diff = arfcn - 2937;
        Nul = 2712 + diff;
      }
      else if (((arfcn >= 9237) && (arfcn <= 9387))) {
        FDL_offset = 0;
        FUL_offset = 0;
        diff = arfcn - 9237;
        Nul = 8762 + diff;
      }
      else if (((arfcn >= 3112) && (arfcn <= 3388))) {
        FDL_offset = 1490;
        FUL_offset = 1135;
        diff = arfcn - 3112;
        Nul = 2887 + diff;
      }
      else if (((arfcn >= 3712) && (arfcn <= 3787))) {
        FDL_offset = 736;
        FUL_offset = 733;
        diff = arfcn - 3712;
        Nul = 3487 + diff;
      }
      else if (((arfcn >= 3842) && (arfcn <= 3903))) {
        FDL_offset = -37;
        FUL_offset = -22;
        diff = arfcn - 3842;
        Nul = 3617 + diff;
      }
      else if (((arfcn >= 4017) && (arfcn <= 4043))) {
        FDL_offset = -55;
        FUL_offset = 21;
        diff = arfcn - 4017;
        Nul = 3792 + diff;
      }
      else if (((arfcn >= 4117) && (arfcn <= 4143))) {
        FDL_offset = -63;
        FUL_offset = 12;
        diff = arfcn - 4117;
        Nul = 3892 + diff;
      }
      else if (((arfcn >= 712) && (arfcn <= 763))) {
        FDL_offset = 735;
        FUL_offset = 770;
        diff = arfcn - 712;
        Nul = 312 + diff;
      }
      else if (((arfcn >= 4512) && (arfcn <= 4638))) {
        FDL_offset = -109;
        FUL_offset = -23;
        diff = arfcn - 4512;
        Nul = 4287 + diff;
      }
      else if (((arfcn >= 862) && (arfcn <= 912))) {
        FDL_offset = 1326;
        FUL_offset = 1358;
        diff = arfcn - 862;
        Nul = 462 + diff;
      }
      else if (((arfcn >= 4662) && (arfcn <= 5038))) {
        FDL_offset = 2580;
        FUL_offset = 2525;
        diff = arfcn - 4662;
        Nul = 4437 + diff;
      }
      else if (((arfcn >= 5112) && (arfcn <= 5413))) {
        FDL_offset = 910;
        FUL_offset = 875;
        diff = arfcn - 5112;
        Nul = 4887 + diff;
      }
      else if (((arfcn >= 5762) && (arfcn <= 5913))) {
        FDL_offset = -291;
        FUL_offset = -291;
        diff = arfcn - 5762;
        Nul = 5537 + diff;
      }
      else {
        FDL_offset = 0;
        FUL_offset = 0;
        diff = 0;
        Nul = 0;
      }
      download = (FDL_offset + (0.2 * arfcn));
    }
    if (type.indexOf("5G") >= 0) {
      if (arfcn >= 0 && arfcn <= 599999) {
        download = 0 + 5 * (arfcn - 0);
      } else if (arfcn >= 600000 && arfcn <= 2016666) {
        download = 3000 + 15 * (arfcn - 600000);
      } else if (arfcn >= 2016667 && arfcn <= 3279165) {
        download = 24250.08 + 60 * (arfcn - 20166670);
      }
    }
    return new frequenze(download);
  }

  private static getBand(modem: number, type: string, band: string): string {
    let s = "";
    if (modem == 3) {
      const arfcn = (Number)(band);
      if (type.indexOf("G") >= 0) {
        const myBand = gsmBands.find(o => arfcn >= o.earfcn[0] && arfcn <= o.earfcn[1]);
        if (myBand) {
          s = myBand.band;
        } else {
          s = "";
        }
        return s;
      } else if (type.indexOf("U") >= 0) {
        const myBand = umtsBands.find(o => arfcn >= o.earfcn[0] && arfcn <= o.earfcn[1]);
        if (myBand) {
          s = myBand.band;
        } else {
          s = "";
        }
        return s;
      }

    }

    if (band.indexOf("7FFFFFFFFFFFFFFF") >= 0) {
      return "All bands";
    }
    const band_ = (Number)(band);
    if (type.indexOf("L") >= 0) {
      const arfcn = (Number)(band);
      const myBand = lteBands.find(o => o.dl_earfcn && arfcn >= o.dl_earfcn[0] && arfcn <= o.dl_earfcn[1]);
      if (myBand) {
        s = "B" + myBand.band + " " + myBand.duplex;
      }
      else {
        s = "";
      }
      return s;
    } else {
      switch (band_) {
        case 80000:
          s = "GSM 850";
          break;
        case 80:
          s = "GSM DCS systems";
          break;
        case 100:
          s = "Extended GSM 900";
          break;
        case 200:
          s = "Primary GSM 900";
          break;
        case 100000:
        case 100300:
          s = "Railway GSM 900";
          break;
        case 200000:
          s = "GSM PCS";
          break;
        case 400000:
          s = "WCDMA IMT 2100";
          break;
        case 800000:
          s = "WCDMA_II_PCS_1900";
          break;
        case 4000000:
          s = "WCDMA_V_850";
          break;
        case 8000000:
          s = "WCDMA_VI_800";
          break;
        case 4000000000000:
          s = "WCDMA_IX_1700";
          break;
        case 2000000000000:
          s = "WCDMA_VIII_900";
          break;
        case 1000000000000000:
          s = "WCDMA_XIX_850";
          break;
        case 2000000:
          s = "AWS";
          break;
        case 680380:
          s = "Automatic";
          break;
        default:
          s = "" + band;
          break;
      }
      return s;
    }
  }

  private static getOperatore(mcc_mnc: string): OperatoriTel {
    let operatore, img;
    switch (mcc_mnc) {
      // 230 Repubblica Ceca
      case "230-01":
        operatore = "T-Mobile";
        img = "https://www.t-mobile.com/content/dam/t-mobile/ntm/branding/logos/corporate/tmo-logo-v4.svg";
        break;
      case "230-02":
        operatore = "O2";
        img = "https://it.wikipedia.org/wiki/File:O2.svg";
        break;
      case "230-04":
        operatore = "Nordic Telecom s.r.o.";
        img = "https://aeqqktywno.cloudimg.io/v7/_atmimg_prod_/images/profiles/company-2937018/f40defa0-d172-40f0-ba7a-24eab9f83c6b.svg";
        break;
      case "230-05":
        operatore = "PODA a.s.";
        img = "https://media.licdn.com/dms/image/D4E0BAQHVHspSIXDchw/company-logo_200_200/0/1681742275515?e=2147483647&v=beta&t=vpwTVJHf9S-r31kAyvnB9gP4HdfUdiYuHVVXaonJNxY";
        break;
      case "230-06":
        operatore = "Nordic Telecom 5G a.s.";
        img = "https://i.iinfo.cz/images/235/NT_2.jpg";
        break;
      case "230-07":
        operatore = "ASTELNET, s.r.o.";
        img = "http://www.astelnet.com/files/bluemarine_logo.png";
        break;
      case "230-08":
        operatore = "Compatel s.r.o.";
        img = "http://www.compatel.com/public/images/logo.png";
        break;
      case "230-09":
        operatore = "Vectone Mobile";
        img = "https://www.vectonemobile.co.uk/sites/default/files/logo_2.png";
        break;
      case "230-98":
        operatore = "Správa železniční dopravní cesty, s.o.";
        img = "https://www.spravazeleznic.cz/szdc-theme-web/images/szdc/szdc-logo-barevne.svg";
        break;
      // 222 Italia
      case "222-37": case "222-88":
        operatore = "Wind Tre";
        img = "https://d1qsjop7tycsfb.cloudfront.net/typo3conf/ext/wind_onebrand/Resources/Public/img/mm_header/windtre-logo-mob.png";
        break;
      case "222-99":
        operatore = "3 Italia";
        img = "https://d1qsjop7tycsfb.cloudfront.net/typo3conf/ext/wind_onebrand/Resources/Public/img/mm_header/windtre-logo-mob.png";
        break;
      case "222-34":
        operatore = "BT Italia";
        img = "https://upload.wikimedia.org/wikipedia/commons/thumb/c/cd/BT_logo_2019.svg/800px-BT_logo_2019.svg.png";
        break;
      case "222-53":
        operatore = "COOP Voce";
        img = "https://www.coopvoce.it/etc.clientlibs/coopvoce/clientlibs/clientlib-site/resources/images/logo-coopvoce.svg";
        break;
      case "222-08":
        operatore = "Fastweb S.p.A.";
        img = "https://upload.wikimedia.org/wikipedia/it/archive/4/42/20230104192916%21Fastweb_logo_flat.png";
        break;
      case "222-50":
        operatore = "Iliad";
        img = "https://www.iliad.it/assets/images/logo.png";
        break;
      case "222-33":
        operatore = "Poste Mobile S.p.A.";
        img = "http://www.postemobile.it/ClientLibrary/img/share/logo-postemobile.png";
        break;
      case "222-39":
        operatore = "SMS Italia S.r.l.";
        img = "https://www.sms.it/user/themes/smsh/assets/logo_smsit_o_from.png";
        break;
      case "222-47":
        operatore = "Tiscali S.p.A.";
        img = "https://casa.tiscali.it/img/logo.png";
        break;
      case "222-01": case "222-43": case "222-48":
        operatore = "Tim";
        img = "https://risorse.tim.it/content/dam/flytoco-areapubblica-aemfe/loghi/logo.svg";
        break;
      case "222-10": case "222-06": case "214-01": case "214-06": case "214-18": case "214-24": case "214-37": case "268-01": case "268-91": case "230-03": case "230-99":
        operatore = "Vodafone";
        img = "https://privati.vodafone.it/favicon-32x32.png";
        break;
      case "222-38":
        operatore = "Linkem S.p.A.";
        img = "https://www.linkem.com/-/media/linkem/images/logo/logo-linkem-new.png?h=140&w=320&hash=7F4116801FB8D7C2C4C5E58256B123CBA8153816";
        break;
      // 214 Spagna
      case "214-02":
        operatore = "Alta Tecnologia en Comunicacions SL";
        img = "https://www.altecom.net/images/logo-altecom.jpg";
        break;
      case "214-03": case "214-09": case "214-19": case "214-21":
        operatore = "Orange Espagne";
        img = "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c8/Orange_logo.svg/150px-Orange_logo.svg.png";
        break;
      case "214-04": case "214-23": case "214-29": case "214-33":
        operatore = "Xfera Moviles";
        img = "https://upload.wikimedia.org/wikipedia/commons/6/66/Yoigo_morado.svg";
        break;
      case "214-05": case "214-07": case "214-32": case "214-38":
        operatore = "Telefónica Móviles España";
        img = "https://upload.wikimedia.org/wikipedia/commons/thumb/5/55/Movistar_isotype_2020.svg/1280px-Movistar_isotype_2020.svg.png";
        break;
      case "214-08":
        operatore = "Euskaltel";
        img = "https://upload.wikimedia.org/wikipedia/commons/2/2d/Euskaltel_2018_logo.svg";
        break;
      case "214-11":
        operatore = "Telecom Castilla-La Mancha";
        img = "https://telecomclm.net/wp-content/uploads/2019/04/cropped-operador-2.png";
        break;
      case "214-13":
        operatore = "Syma Mobile España";
        img = "https://www.symamobile.com/version-202001/images/logo_syma.svg";
        break;
      case "214-16": case "214-17":
        operatore = "R Cable y Telecomunicaciones Galicia S.A.";
        img = "https://en.wikipedia.org/wiki/File:R_Cable_logo.svg";
        break;
      case "214-20":
        operatore = "Fonyou Telecom";
        img = "https://fonyou.com/wp-content/themes/beep-fonyou/assets/images/logos/footer_logo.svg";
        break;
      case "214-25": case "268-04": case "311-960":
        operatore = "LycaMobile";
        img = "https://upload.wikimedia.org/wikipedia/commons/thumb/2/24/Lycamobile.svg/1920px-Lycamobile.svg.png";
        break;
      case "214-26":
        operatore = "Lleida Networks Serveis Telemátics, SL";
        img = "https://www.lleida.net/img/logos/logo-lleidanet.png";
        break;
      case "214-27":
        operatore = "SCN Truphone, S.L.";
        img = "https://encrypted-tbn2.gstatic.com/images?q=tbn:ANd9GcRV4n6Y6UlLkNeXgq5Rczv0TTT3rBDC0mNuQvowIg9oTPfaz-jJ";
        break;
      case "214-28":
        operatore = "Consorcio de Telecomunicaciones Avanzadas, S.A.";
        img = "http://www.cotatelecom.com/wp-content/uploads/2016/11/Logo-transparente.png";
        break;
      case "214-30":
      case "268-11":
        operatore = "Compatel Limited";
        img = "";
        break;
      case "214-31":
        operatore = "Red Digital De Telecomunicacioned de las lslas Baleares";
        img = "";
        break;
      case "214-34":
        operatore = "Aire Networks del Mediterráneo";
        img = "";
        break;
      case "214-35":
        operatore = "INGENIUM OUTSOURCING SERVICES";
        img = "";
        break;
      case "214-36":
        operatore = "ALAI OPERADOR DE TELECOMUNICACIONES";
        img = "https://www.hostelvending.com/image/sys_companies/700x0/alai-operador-de-telecomunicaciones-s.l.png";
        break;
      case "214-51":
        operatore = "Administrador de Infraestructuras Ferroviarias";
        img = "https://www.adif.es/o/adif-theme/images/color_schemes/adif/logo-adif.svg";
        break;
      // 424 Emirati Arabi
      case "424-02":
        operatore = "Emirates Telecom Corp";
        img = "https://www.etisalat.ae/content/dam/etisalat/consumer/nwt/mega-menu/etisalat-logo/etisalat-logo.svg";
        break;
      case "424-03":
        operatore = "Emirates Integrated Telecommunications Company";
        img = "https://www.du.ae/servlet/duaediscovery/common/discovery/common/images/about-us-footer.svg";
        break;
      // 268 Portogallo
      case "268-02": case "268-6": case "268-80":
        operatore = "Telecomunicações Móveis Nacionais";
        img = "https://conteudos.meo.pt/Style%20Library/consumo/images/logo_meo_preto.png";
        break;
      case "268-03":
        operatore = "NOS Comunicações";
        img = "https://galeria.nos.pt/Style%20Library/5G/images/logo-nos.svg";
        break;
      case "268-05":
        operatore = "Oniway - Inforcomunicaçôes, S.A.";
        img = "https://en.wikipedia.org/wiki/File:Oni_logo2.png";
        break;
      case "268-07":
        operatore = "Mundio Mobile (Portugal) Limited";
        img = "https://www.vectonemobile.co.uk/sites/default/files/logo_2.png";
        break;
      case "268-12":
        operatore = "Infraestruturas de Portugal, S.A.";
        img = "https://www.infraestruturasdeportugal.pt/themes/ip_corporativo_theme/images/logo-cor.png";
        break;
      case "268-13":
        operatore = "G9Telecom, S.A.";
        img = "https://www.g9telecom.pt/img/logo.png";
        break;
      case "268-21":
        operatore = "Zapp Portugal";
        img = "";
        break;
      // 334 Messico
      case "334-01": case "334-04": case "334-05": case "334-09": case "334-10": case "334-40": case "334-50": case "334-90":
        operatore = "AT&T Mexico";
        img = "	https://www.att.com.mx/content/dam/ATT/personal/lading/home-assets/marquee/homer/menu/logo-att.svg";
        break;
      case "334-02": case "334-20":
        operatore = "Telcel";
        img = "https://www.telcel.com/content/dam/htmls/img/icons/logo-telcel.svg";
        break;
      case "334-03": case "334-30": case "334-45":
        operatore = "Movistar";
        img = "https://upload.wikimedia.org/wikipedia/commons/thumb/5/55/Movistar_isotype_2020.svg/1280px-Movistar_isotype_2020.svg.png";
        break;
      case "334-60":
        operatore = "Servicios de Acceso Inalambrico, S.A. de C.V.";
        img = "";
        break;
      case "334-66":
        operatore = "Telmex";
        img = "https://telmex.com/documents/20194/7582410/telmex.svg/fd1183b3-c08d-7c40-86f0-cec5dd75c6d7";
        break;
      case "334-70": case "334-80":
        operatore = "Unefon";
        img = "https://www.unefon.com.mx/img/unefon-logo-black.png";
        break;
      case "334-140":
        operatore = "Altán Redes S.A.P.I. de C.V.";
        img = "https://cdn-www.bnamericas.com/img/logo_bna.png";
        break;
      case "334-299":
        operatore = "Carriers de otros paises";
        img = "";
        break;
      case "334-999":
        operatore = "FIX LINE";
        img = "";
        break;
      case "313-100": case "313-110": case "313-190":
        operatore = "700 MHz Public Safety Broadband";
        img = "https://www.firstnet.gov/sites/default/files/FirstNetAuthorityLogo.jpg";
        break;
      // 312 Stati Uniti d'America
      case "312-500":
        operatore = "AB Spectrum LLC";
        img = "";
        break;
      case "312-70":
        operatore = "Adams Networks Inc";
        img = "";
        break;
      case "310-880":
        operatore = "Advantage Cellular Systems, Inc.";
        img = "https://www.dtcwireless.com/wp-content/uploads/2017/01/logo-1.jpg";
        break;
      case "310-850":
        operatore = "Aeris Communications, Inc.";
        img = "https://www.aeris.com/wp-content/uploads/2022/01/aeris-logo.svg";
        break;
      case "310-630":
        operatore = "Agri-Valley Communications";
        img = "https://ipid72.p3cdn1.secureserver.net/wp-content/uploads/2022/01/agri-valley-services-logo.png";
        break;
      case "310-34":
        operatore = "Airpeak";
        img = "";
        break;
      case "310-50": case "310-190":
        operatore = "Alaska Communications";
        img = "https://www.alaskacommunications.com/-/media/Images/AKCS_Global/akcs-logo-expect-more.ashx?h=100&w=190&la=en&hash=9C834A2C8A68163098BC405BD9D0C99B";
        break;
      case "312-880":
        operatore = "Albemarle County Public Schools";
        img = "";
        break;
      case "310-54":
        operatore = "Alltel US";
        img = "";
        break;
      case "313-390":
        operatore = "Altice USA Wireless, Inc.";
        img = "https://alticeusadev2.prod.acquia-sites.com/sites/default/files/2022-05/ALTICE_LOGO_HORIZONTAL_REV_TRANS_RGB.png";
        break;
      // 311 Stati uniti d'America
      case "311-770":
        operatore = "Altiostar Networks, Inc.";
        img = "";
        break;
      case "311-780":
        operatore = "American Samoa Telecommunications";
        img = "https://www.astca.net/wp-content/uploads/2018/08/Logo.png";
        break;
      case "311-30":
        operatore = "Americell PA 3 Partnership";
        img = "https://www.indigowireless.com/uploads/7/0/1/7/70179849/indigo-logo.jpg";
        break;
      case "311-200":
        operatore = "ARINC";
        img = "https://it.wikipedia.org/wiki/File:ARINC_logo.svg";
        break;
      case "312-750":
        operatore = "Artemis Networks LLC";
        img = "https://assets.website-files.com/52f04b50d6dbc2930f000490/622598a5639e30842ea2bdc2_artemis_title_home%402x.png";
        break;
      case "313-440":
        operatore = "Arvig Enterprises, Inc.";
        img = "https://www.arvig.com/wp-content/uploads/2018/11/arvig_logo.png";
        break;
      case "310-16": case "310-30": case "310-70": case "310-80": case "310-90": case "310-150": case "310-170": case "310-280": case "310-380": case "310-410": case "310-560":
      case "310-670": case "310-680": case "310-950": case "311-70": case "311-90": case "311-180": case "311-190": case "312-90": case "312-670": case "312-680": case "313-210":
        operatore = "AT&T Mobility";
        img = "http://t0.gstatic.com/images?q=tbn:ANd9GcRoyThCKtMvxaPQrZ0aNYDoBbTs6m-aQprv47XhaSuynSmsSZon";
        break;
      case "311-570":
        operatore = "Bend Cable Communications LLC";
        img = "https://hellotds.com/content/dam/tdstelecom/images/logos/tds-logo.svg";
        break;
      case "311-970":
        operatore = "Big River Broadband, LLC";
        img = "https://residential.bigrivercom.com/wp-content/uploads/br-logo.png";
        break;
      case "313-270":
        operatore = "Blackstar Management";
        img = "";
        break;
      case "311-440": case "311-800": case "311-810":
        operatore = "Bluegrass Cellular LLC";
        img = "https://www.verizon.com/about/sites/default/files/2021-03/bluegrass-cellular-2-700x393.jpg";
        break;
      case "544-11":
        operatore = "Bluesky";
        img = "https://blueskyweb.org/images/logo-32x32.jpg";
        break;
      case "312-810":
        operatore = "Bristol Bay Telephone Cooperative";
        img = "https://www.bristolbay.com/uploads/8/2/1/8/82184268/published/bbtc-logo-for-website.jpg?1609437968";
        break;
      case "312-540":
        operatore = "Broadband In Hand LLC";
        img = "";
        break;
      case "312-570":
        operatore = "Blue Wireless";
        img = "";
        break;
      case "311-330":
        operatore = "Bug Tussel Wireless LLC";
        img = "https://btussel.com/wp-content/themes/webfitters/assets/images/logo.png";
        break;
      case "310-900": case "312-450":
        operatore = "Cable & Communications Corporation";
        img = "https://cable-com.com/wp-content/uploads/2017/10/cci_script_logo_sm.jpg";
        break;
      case "313-310":
        operatore = "CAL.NET, Inc.";
        img = "https://www.calnet.com/public/img/calnet_logo.png";
        break;
      case "313-300":
        operatore = "Cambio WiFi of Delmarva, LLC";
        img = "";
        break;
      case "313-20":
        operatore = "Cambridge Telephone Company Inc.";
        img = "https://ctcweb.net/Portals/0/Logo_Vector.png?ver=2017-10-04-120534-490";
        break;
      case "310-130": case "312-470":
        operatore = "Carolina West Wireless";
        img = "";
        break;
      case "310-360": case "312-270": case "312-280":
        operatore = "Cellular Network Partnership";
        img = "https://gopioneer.com/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flogo-dark.02d7e1b2.png&w=128&q=75";
        break;
      case "311-230": case "311-630":
        operatore = "Cellular South Inc.";
        img = "https://en.wikipedia.org/wiki/File:Cspire-logo.png";
        break;
      case "312-260":
        operatore = "Central LTE Holdings";
        img = "";
        break;
      case "313-330":
        operatore = "CenturyTel Broadband Services LLC";
        img = "";
        break;
      case "311-10": case "312-220":
        operatore = "Chariton Valley Communications";
        img = "https://www.cvalley.net/wp-content/uploads/2016/08/logo.svg";
        break;
      case "310-420":
        operatore = "Cincinnati Bell Wireless";
        img = "https://login.altafiber.com/cinbell-logo-color.png";
        break;
      case "312-890":
        operatore = "Circle Gx";
        img = "https://a-cloud.b-cdn.net/media/iW=210&iH=210&oX=2&oY=0&cW=206&cH=210/9d4c4988b9c846c1b5c03ad43957daf8.png";
        break;
      case "312-800":
        operatore = "Cirrus Core Networks";
        img = "";
        break;
      case "315-10":
        operatore = "Citizens Broadband Radio Service";
        img = "";
        break;
      case "312-310":
        operatore = "Clear Stream Communications, LLC";
        img = "https://clearstreamcomms.com/wp-content/uploads/2016/11/Clearstream-logo.png";
        break;
      case "312-100":
        operatore = "ClearSky Technologies, Inc.";
        img = "http://www.csky.com/wp-content/uploads/2018/08/ClearSkyTechnologies_twotone_v2-250x100.jpg";
        break;
      case "312-140":
        operatore = "Cleveland Unlimited, Inc.";
        img = "https://en.wikipedia.org/wiki/File:Revol_logo_new.png";
        break;
      case "311-790":
        operatore = "Coleman County Telephone Cooperative, Inc.";
        img = "https://www.cctelco.org/wp-content/uploads/2017/04/logo-white-small.png";
        break;
      case "311-40": case "311-320": case "312-370": case "311-550":
        operatore = "Commnet Wireless";
        img = "https://www.commnetbroadband.com/uploads/1/3/9/6/139656345/published/commnetlogo-horizontalwbg.png?1643041259";
        break;
      case "310-60": case "312-440":
        operatore = "Consolidated Telcom";
        img = "https://consolidatednd.com/wp-content/uploads/2022/06/ConsLogoVector-01-NO-TAGLINE-768x198.png";
        break;
      case "310-930": case "312-380":
        operatore = "Copper Valley Wireless";
        img = "https://ruralwireless.org/wp-content/uploads/2010/09/Copper-Valley-Wireless-logo.jpeg";
        break;
      case "311-240":
        operatore = "Cordova Wireless";
        img = "https://www.ctcak.net/wp-content/uploads/2021/01/CWC-solid-background-980x457.jpg";
        break;
      case "313-60":
        operatore = "Country Wireless";
        img = "https://countrywireless.com/wp-content/uploads/2016/11/cropped-admin-ajax-1-2.png";
        break;
      case "312-60":
        operatore = "CovarageCo";
        img = "";
        break;
      case "311-140":
        operatore = "Cross Telephone Company";
        img = "https://www.crosstel.net/images/logos/2022-OCT-logo-01.png";
        break;
      case "310-700":
        operatore = "Cross Valiant Cellular Partnership";
        img = "";
        break;
      case "312-30": case "312-10":
        operatore = "Cross Wireless";
        img = "https://bravadowireless.com/wp-content/themes/theme-bravado/assets/images/Bravado_Logo_White.png";
        break;
      case "312-40": case "312-950": case "313-220":
        operatore = "Custer Telephone Co-op (CTCI)";
        img = "https://custertel.net/wp-content/uploads/2021/08/cropped-FullColor.png";
        break;
      case "310-780":
        operatore = "Dispatch Direct";
        img = "https://dct.aero/wp-content/uploads/2020/02/dd-logo-mail.svg";
        break;
      case "313-340": case "313-350": case "313-360":
        operatore = "Dish Network";
        img = "https://it.wikipedia.org/wiki/File:Dish_Network_2019.svg";
        break;
      case "313-30":
        operatore = "Eagle Telephone System Inc.";
        img = "";
        break;
      case "310-750": case "312-120": case "312-130": case "312-910":
        operatore = "East Kentucky Network, LLC";
        img = "https://www.appalachianwireless.com/images/AppalachianWirelessLogo.png";
        break;
      case "311-760":
        operatore = "Edigen Inc.";
        img = "";
        break;
      case "311-460":
        operatore = "Electric Imp Inc.";
        img = "";
        break;
      case "310-610":
        operatore = "Elkhart Telephone Co.";
        img = "";
        break;
      case "312-410":
        operatore = "Eltopia Communications, LLC";
        img = "http://wp.eltopia.com/wp-content/uploads/2014/09/eltopia-sm.jpg";
        break;
      case "311-950":
        operatore = "Enhanced Telecommmunications Corp.";
        img = "https://www.etd.com/wp-content/uploads/2018/03/ETD-Logo-1.jpg";
        break;
      case "310-35":
        operatore = "ETEX Communications, LP";
        img = "https://www.etex.net/wp-content/uploads/2015/07/etex-3d-logo-1.svg";
        break;
      case "310-990":
        operatore = "Evolve Cellular Inc.";
        img = "https://uploads-ssl.webflow.com/6234b7acf63b34064d6110fb/6234c1c7f5197a4f5d35d9d9_Evolve%20Cellular%20Logo%20White%20on%20Transparent%20Background%20(small).png";
        break;
      case "313-260":
        operatore = "Expeto Wireless Inc.";
        img = "";
        break;
      case "310-311":
        operatore = "Farmers Wireless";
        img = "";
        break;
      case "313-520":
        operatore = "Florida Broadband, Inc.";
        img = "https://lightyear.ai/images/home/lightyear-logo-white.svg";
        break;
      case "312-390":
        operatore = "FTC Communications LLC";
        img = "https://www.ftc.ch/wp-content/uploads/ressources/logo_ftc.svg";
        break;
      case "312-50":
        operatore = "Fuego Wireless";
        img = "";
        break;
      case "313-240":
        operatore = "Fundamental Holdings, Corp.";
        img = "";
        break;
      case "310-430":
        operatore = "GCI Communications Corp.";
        img = "https://www.gci.com/-/media/images/gci/global/header/gci_logo_same_dimension_bottom_aligned.png?h=60&iar=0&mh=60&w=80&hash=29DD92F2DCE81D0F33229F43F758E2A9";
        break;
      case "311-370":
        operatore = "General Communication Inc.";
        img = "http://www.generalcommunicationsrl.it/wp-content/uploads/2016/05/logo-trasparente-general-communication.gif";
        break;
      case "311-900": case "312-870":
        operatore = "GigSky";
        img = "https://static.wixstatic.com/media/58c0c3_4295422bbcf948a083c3f67019d053a9~mv2.png/v1/crop/x_0,y_0,w_553,h_123/fill/w_296,h_70,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/gs_banner_white_logo.png";
        break;
      case "312-790":
        operatore = "Gila Electronics";
        img = "http://gilaelectronics.com/wp-content/uploads/2017/10/GE-white-clear.png";
        break;
      case "310-970":
        operatore = "Globalstar";
        img = "https://www.globalstar.com/Globalstar/media/Globalstar/Images/Logos/GlobalstarEmblemLogo.png";
        break;
      case "311-890":
        operatore = "Globecomm Network Services Corporation";
        img = "";
        break;
      case "312-620":
        operatore = "GlobeTouch Inc.";
        img = "https://www.airlinq.com/wp-content/uploads/2020/03/PNG-FINAL-EXPORT-1-1536x1070-1.png";
        break;
      case "312-710":
        operatore = "Great North Woods Wireless LLC";
        img = "";
        break;
      case "312-550":
        operatore = "Great Plains Communications, Inc.";
        img = "	https://gpcom.com/wp-content/uploads/2019/06/gpc-logo-1000.svg";
        break;
      case "310-33":
        operatore = "Guam Telephone Authority";
        img = "Guam Telephone Authority";
        break;
      case "311-680":
        operatore = "GreenFly LLC";
        img = "https://www.greenfly.com/wp-content/themes/greenfly/assets/images/greenfly-logo.svg#logo";
        break;
      case "313-290":
        operatore = "Gulf Coast Broadband LLC";
        img = "	https://vportal.visp.net/gulfcoastwifi/wp-content/…/sites/137/2017/09/Web_Size_Transparent_Bkgrd.png";
        break;
      case "312-930":
        operatore = "Hewlett-Packard Communication Services  LLC";
        img = "";
        break;
      case "310-540":
        operatore = "Hilliary Communications";
        img = "	https://hilliary.com/wp-content/uploads/2022/08/hill_logo_square.png";
        break;
      case "313-420":
        operatore = "Hudson Valley Wireless";
        img = "https://www.hvwisp.com/wp-content/uploads/2021/02/cropped-HVW-Logo-400x100-Trans-1.png";
        break;
      case "311-340":
        operatore = "Illinois Valley Cellular";
        img = "https://i0.wp.com/www.ivcel.com/wp-content/uploads/2019/06/ivc_newweblogo.png?fit=800%2C168&ssl=1";
        break;
      case "313-250":
        operatore = "Imperial County Office of Education";
        img = "https://www.icoe.org/themes/icoe/icoe-logo.png";
        break;
      case "312-20":
        operatore = "Infrastructure Networks  LLC";
        img = "https://inetlte.com/wp-content/themes/inet/img/inet.svg";
        break;
      case "310-580":
        operatore = "Inland Cellular Telephone Company";
        img = "https://inlandcellular.com/wp-content/uploads/2020/07/IC-Logo-Flat-large.png";
        break;
      case "312-970":
        operatore = "IOSAZ Intellectual Property LLC";
        img = "";
        break;
      case "311-410":
      case "312-170":
        operatore = "Iowa RSA No. 2 LP";
        img = "	https://rsaorg.com/wp-content/uploads/2017/10/RSALogo_SmallDark.png";
        break;
      case "310-770":
      case "310-530":
        operatore = "Iowa Wireless Services LLC";
        img = "	https://upload.wikimedia.org/wikipedia/en/thumb/b/bf/IWireless_logo.png/220px-IWireless_logo.png";
        break;
      case "310-300":
        operatore = "iSmart Mobile  LLC";
        img = "";
        break;
      case "310-32":
        operatore = "IT&E Overseas  Inc";
        img = "https://store.ite.net/wp-content/uploads/2020/09/ITE-LOGO.png";
        break;
      case "310-920":
        operatore = "James Valley Wireless  LLC";
        img = "https://jamesvalley.com/wp-content/uploads/2021/03/jvcameraready_black_web.png";
        break;
      case "310-650":
        operatore = "Jasper Technologies";
        img = "";
        break;
      case "310-870":
        operatore = "Kaplan Telephone Company";
        img = "https://cdn.shopify.com/s/files/1/2078/7729/files/kaptellogowithwebsite3_550x.png?v=1613733865";
        break;
      case "312-740":
        operatore = "KDDI America  Inc.";
        img = "https://us.kddi.com/files/user/common/img/header/KDDI_America.png";
        break;
      case "312-460":
        operatore = "Ketchikan Public Utilities (KPU)";
        img = "";
        break;
      case "313-280":
        operatore = "King Street Wireless  LP";
        img = "https://broadbandnow.com/images/King-Street-Wireless-5727.png";
        break;
      case "312-830":
        operatore = "Kings County Office of Education";
        img = "https://www.kingscoe.org/cms/lib/CA50000415/Centri…late/GlobalAssets/images///logos/KCOE_280x280.png";
        break;
      case "311-310":
        operatore = "Leaco Rural Telephone Company Inc.";
        img = "https://img1.wsimg.com/isteam/ip/44d07865-8641-4d93-8ef6-53f716be49c9/Capture-35d86a1.PNG/:/rs=w:481,h:200,cg:true,m/cr=w:481,h:200/qt=q:95";
        break;
      case "311-510": case "311-130": case "311-160": case "311-520":
        operatore = "Lightsquared L.P.";
        img = "https://en.wikipedia.org/wiki/File:Ligado_Networks_logo.png";
        break;
      case "311-980":
        operatore = "LigTel Communications";
        img = "https://www.ligtel.com/images/LigTel-Logo.png";
        break;
      case "312-180": case "310-340": case "310-690": case "311-600":
        operatore = "Limitless Mobile  LLC";
        img = "https://limitlessmobile.com/content/limitless-logo.png";
        break;
      case "310-760":
        operatore = "Lynch 3G Communications Corporation";
        img = "";
        break;
      case "312-960":
        operatore = "M&A Technology  Inc.";
        img = "https://www.macomp.com/wp-content/uploads/thegem-logos/logo_a5e80175885aa73a037789ed1de97f1c_1x.png";
        break;
      case "311-720":
        operatore = "MainePCS LLC";
        img = "";
        break;
      case "313-50":
        operatore = "Manti Tele Communications Company  Inc.";
        img = "https://www.manti.com/wp-content/uploads/MTCC-Logo-2017-W-out_Connecting-White-e1497277684551.png";
        break;
      case "312-980":
        operatore = "Mark Twain Communications Company";
        img = "https://portal.marktwain.net/wp-content/uploads/2017/11/mt-logo-234x74.png";
        break;
      case "310-40":
      case "312-340":
        operatore = "Matanuska Telephone Association  Inc.";
        img = "";
        break;
      case "313-200":
        operatore = "Mercury Network Corporation";
        img = "https://www.mercury.net/img/logo.png";
        break;
      case "311-660":
        operatore = "Metro by T-Mobile";
        img = "https://www.metrobyt-mobile.com/content/dam/digx/m…n/branding/logos/corporate/metro-d-light-2022.svg";
        break;
      case "312-400":
        operatore = "Mid-Rivers Telephone Cooperative";
        img = "https://midrivers.com/wp-content/uploads/2020/04/MR-FullColor.png";
        break;
      case "311-00":
        operatore = "Mid-Tex Cellular Ltd.";
        img = "";
        break;
      case "311-700": case "313-70":
        operatore = "Midwest Network Solutions Hub LLC";
        img = "";
        break;
      case "313-550":
        operatore = "Mile High Networks LLC";
        img = "https://static.wixstatic.com/media/8a2422_7cf6dd19…66_1.00_0.01,enc_auto/MileHighNetworks_edited.png";
        break;
      case "311-20": case "311-920":
        operatore = "Missouri RSA 5 Partnership";
        img = "";
        break;
      case "313-460":
        operatore = "Mobi  Inc.";
        img = "";
        break;
      case "312-580":
        operatore = "Morgan  Lewis & Bockius LLP";
        img = "https://www.morganlewis.com/-/media/starterkit/ml-logo_white_281x45.png?rev=f51b3f514b9c4a00a26938252dcfd715";
        break;
      case "311-500":
        operatore = "Mosaic Telecom";
        img = "https://experiencemosaic.com/wp-content/uploads/2022/09/Mosaic-Logo-Horizontal-210.png";
        break;
      case "312-330":
        operatore = "Nemont Communications  Inc.";
        img = "";
        break;
      case "310-290":
        operatore = "NEP Cellcorp Inc.";
        img = "";
        break;
      case "312-630":
        operatore = "NetGenuity  Inc.";
        img = "http://netgenuity.com/wp-content/uploads/2016/08/cropped-NetGenuity-Logo-Small.jpg";
        break;
      case "311-380":
        operatore = "New Dimension Wireless Ltd.";
        img = "";
        break;
      case "310-100":
        operatore = "New Mexico RSA 4 East LP";
        img = "";
        break;
      case "310-600":
        operatore = "NewCell Inc.";
        img = "";
        break;
      case "310-460":
      case "311-530":
        operatore = "NewCore Wireless LLC";
        img = "https://www.newcore.ca/images/cmp_logo_fin.jpg";
        break;
      case "311-100": case "312-420":
        operatore = "Nex-Tech Wireless";
        img = "https://www.nex-techwireless.com/assets/images/layout/logo.svg";
        break;
      case "316-10":
        operatore = "Nextel Communications";
        img = "https://en.wikipedia.org/wiki/File:Nextel_logo.svg";
        break;
      case "311-300":
        operatore = "Nexus Communications  Inc.";
        img = "https://nexuscommunications.com/wp-content/uploads/2020/12/Nexus-logo-reverse.svg";
        break;
      case "312-560":
        operatore = "NHLT Inc.";
        img = "";
        break;
      case "313-540":
        operatore = "Nokia Innovations US LLC";
        img = "";
        break;
      case "311-610": case "312-230":
        operatore = "North Dakota Network Co.";
        img = "https://www.ndit.nd.gov/sites/www/files/styles/logo/public/documents/theme/Information-Technology-SWP-white-reverse.png?itok=Ymn_Jc-m";
        break;
      case "310-17":
        operatore = "North Sight Communications Inc.";
        img = "https://northsightpr.com/wp-content/uploads/2020/05/NS-left-Color.png";
        break;
      case "311-710": case "312-920":
        operatore = "Northeast Wireless Networks LLC";
        img = "https://image.pitchbook.com/7ZL4PB5SBTHkQe2We16bIZlXCHO1621240848777_200x200";
        break;
      case "312-590":
        operatore = "Northern Michigan University";
        img = "";
        break;
      case "311-420": case "312-150":
        operatore = "Northwest Missouri Cellular LP";
        img = "https://www.nwmcell.com/themes/custom/rye/images/logo-nwmcell.png";
        break;
      case "310-510": case "311-840": case "311-850":
        operatore = "Nsight";
        img = "https://www.nsight.com/images/nsight-logo.png";
        break;
      case "310-620":
        operatore = "Nsighttel Wireless LLC";
        img = "https://www.nsighttel.com/wp-content/uploads/2020/04/Header-Nsight-Logo-1.svg";
        break;
      case "312-610": case "312-660":
        operatore = "nTelos Wireless";
        img = "https://en.wikipedia.org/wiki/File:NTelos_Logo.svg";
        break;
      case "310-370": case "310-470":
        operatore = "NTT DoCoMo Pacific";
        img = "";
        break;
      case "313-40":
        operatore = "Nucla-Naturita Telephone Company";
        img = "https://www.nntc.bz/wp-content/uploads/2020/04/NNTC-logo.jpg";
        break;
      case "310-440": case "310-640":
        operatore = "Numerex";
        img = "";
        break;
      case "312-850":
        operatore = "Onvoy Spectrum  LLC";
        img = "";
        break;
      case "313-380":
        operatore = "OptimERA Inc.";
        img = "";
        break;
      case "311-560":
        operatore = "OTZ Communications  Inc.";
        img = "https://www.nwarctic.org/cms/lib/AK01001584/Centri…//logos/Atautchikun%20district%20logo%20small.png";
        break;
      case "310-810":
        operatore = "Pacific Lightwave Inc.";
        img = "https://www.paclw.com/wp-content/uploads/2017/11/logo.png";
        break;
      case "313-320":
        operatore = "Paladin Wireless";
        img = "https://www.paladinwireless.com/files/6614/3628/9559/logo.png";
        break;
      case "311-450":
        operatore = "Panhandle Telecommunication Systems Inc.";
        img = "https://www.ptci.net/wp-content/uploads/2021/03/logo-ptci@2x.png";
        break;
      case "313-410":
        operatore = "pdvWireless";
        img = "https://bearcom.com/static/frontend/Bearcom/ninth/en_US/images/imgpsh_fullsizeanim.png";
        break;
      case "311-670":
        operatore = "Pine Belt Cellular Inc.";
        img = "https://static.wixstatic.com/media/d3cecc_6e03defe…o/d3cecc_6e03defee4ae4476b84ac8b489167bfc~mv2.png";
        break;
      case "311-80":
        operatore = "Pine Telephone Company";
        img = "";
        break;
      case "310-790": case "311-290":
        operatore = "PinPoint Communications Inc.";
        img = "https://static.wixstatic.com/media/c42204_dcb00a1f…c,q_85,usm_0.66_1.00_0.01,enc_auto/pnpt_black.png";
        break;
      case "312-990":
        operatore = "Premier Holdings LLC";
        img = "";
        break;
      case "311-540": case "311-730":
        operatore = "Proximiti Mobility Inc.";
        img = "http://www.proximiti.com/images/proximiti_logo_white.jpg";
        break;
      case "310-110":
        operatore = "PTI Pacifica Inc.";
        img = "";
        break;
      case "310-500":
        operatore = "Public Service Cellular Inc.";
        img = "";
        break;
      case "313-510":
        operatore = "Puerto Rico Telecom Company";
        img = "";
        break;
      case "313-490":
        operatore = "Puloli  Inc.";
        img = "https://puloli.com/wp-content/uploads/2020/04/Puloli_logo_2020.svg";
        break;
      case "313-480":
        operatore = "Ready Wireless  LLC";
        img = "https://readywireless.com/wp-content/uploads/2023/01/cropped-ReadyIoT_LOGO_white-100x39.png";
        break;
      case "313-370":
        operatore = "Red Truck Wireless  LLC";
        img = "";
        break;
      case "312-780":
        operatore = "Redzone Wireless";
        img = "https://www.redzonewireless.com/img/redzone_broadband-for-me.svg";
        break;
      case "314-100": case "314-190":
        operatore = "Reserved for Public Safety";
        img = "";
        break;
      case "311-430":
        operatore = "RSA 1 LP";
        img = "";
        break;
      case "312-160":
        operatore = "RSA1 Limited Partnership";
        img = "";
        break;
      case "312-320":
        operatore = "RTC Communications LLC";
        img = "https://www.rtccom.com/wp-content/uploads/2022/01/RTC-Logo-V01-Variations-07-copy.png";
        break;
      case "311-350": case "312-480": case "312-600": case "312-640":
        operatore = "Sagebrush Cellular  Inc.";
        img = "";
        break;
      case "313-470":
        operatore = "San Diego Gas & Electric Company";
        img = "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d9/Sdgenewlogo.png/220px-Sdgenewlogo.png";
        break;
      case "312-820":
        operatore = "Santel Communications Cooperative  Inc.";
        img = "";
        break;
      case "313-500":
        operatore = "Shelcomm  Inc.";
        img = "";
        break;
      case "311-910":
        operatore = "SI Wireless LLC";
        img = "https://www.systemsintegrated.com/assets/images/si-logo-new-big-transparent-tight-552x128.png";
        break;
      case "312-430":
        operatore = "Silver Star Communications";
        img = "https://www.silverstar.com/wp-content/uploads/2020/09/SSLogo-no-tagline.svg";
        break;
      case "310-320":
        operatore = "Smith Bagley  Inc.";
        img = "";
        break;
      case "311-820":
        operatore = "Sonus Networks";
        img = "https://ribboncommunications.com/themes/custom/ribbon2020/images/ribbon-logo-color-1.svg";
        break;
      case "312-80": case "312-840":
        operatore = "South Georgia Regional Information Technology Authority";
        img = "";
        break;
      case "310-15": case "312-720": case "316-11":
        operatore = "Southern Communications";
        img = "https://www.southern-comms.co.uk/wp-content/themes/southerncomms-scl/img/logo-nov-22.svg";
        break;
      case "311-60":
        operatore = "Space Data Corporation";
        img = "https://spacedata.net/wp-content/uploads/2020/05/logo.png";
        break;
      case "313-450":
        operatore = "Spectrum Wireless Holdings  LLC";
        img = "";
        break;
      case "313-80":
        operatore = "Speedwavz LLP";
        img = "https://speedwavz.net/wp-content/uploads/2019/05/logo.png";
        break;
      case "310-53": case "310-120": case "310-830": case "311-260": case "311-490": case "311-870": case "311-880": case "311-882": case "311-940":
      case "312-190": case "312-240": case "312-250": case "312-530":
        operatore = "Sprint";
        img = "https://www.sistemaproprietaintellettuale.it/public/img/Sprint.png";
        break;
      case "311-640":
        operatore = "Standing Rock Telecommunications";
        img = "https://lirp.cdn-website.com/bced3c6c/dms3rep/multi/opt/Standing-Rock-Telecom-222w.png";
        break;
      case "311-360":
        operatore = "Stelera Wireless";
        img = "https://www.americanlegal.com/theme/americanlegalclaims/images/american_legal_button.png";
        break;
      case "310-550": case "310-720":
        operatore = "Syniverse Technologies";
        img = "";
        break;
      case "311-930":
        operatore = "Syringa Wireless";
        img = "https://uploads-ssl.webflow.com/61a50738b192e1672c7d9e24/61b4e29e1e0ca88604a97042_Syringa_Networks_logo_white.png";
        break;
      case "310-200": case "310-210": case "310-220": case "310-230": case "310-240": case "310-250": case "310-270": case "310-310":
      case "310-490": case "310-800": case "310-160": case "310-260":
        operatore = "T-Mobile USA";
        img = "https://www.t-mobile.com/content/dam/t-mobile/ntm/branding/logos/corporate/tmo-logo-v4.svg";
        break;
      case "311-170":
        operatore = "Tampnet";
        img = "https://www.tampnet.com/hubfs/Profile/logo-tampnet-white.svg";
        break;
      case "311-740":
        operatore = "Telalaska Cellular";
        img = "https://www.telalaska.com/wp-content/uploads/2022/08/thumbnail_image005.jpg";
        break;
      case "311-690":
        operatore = "TeleBEEPER of New Mexico";
        img = "";
        break;
      case "310-840": case "312-300":
        operatore = "Telecom North America Mobile  Inc.";
        img = "https://en.wikipedia.org/wiki/File:2021.04.30-Telna-Logo-360x360.svg";
        break;
      case "310-140":
        operatore = "Teleguam Holdings  LLC";
        img = "https://en.wikipedia.org/wiki/File:GTA_Teleguam_logo.svg";
        break;
      case "311-210":
        operatore = "Telnyx LLC";
        img = "";
        break;
      case "313-00":
        operatore = "Tennessee Wireless";
        img = "http://tennesseewireless.com/wp-content/uploads/2013/11/250-x-68-Banner.jpg";
        break;
      case "311-620":
        operatore = "TerreStar Networks  Inc.";
        img = "";
        break;
      case "312-110":
        operatore = "Texas Energy Network LLC";
        img = "";
        break;
      case "310-980":
        operatore = "Texas RSA 7B3";
        img = "https://rsaorg.com/wp-content/uploads/2017/10/RSALogo_SmallDark.png";
        break;
      case "313-400":
        operatore = "Texoma Communications  LLC";
        img = "";
        break;
      case "312-690":
        operatore = "TGS  LLC";
        img = "";
        break;
      case "311-50": case "311-830":
        operatore = "Thumb Cellular LP";
        img = "";
        break;
      case "310-520":
        operatore = "Transaction Network Services";
        img = "https://tnsi.com/wp-content/uploads/2022/09/brand.svg";
        break;
      case "313-560":
        operatore = "Transit Wireless LLC";
        img = "https://transitwireless.com/wp-content/uploads/cropped-TW-logo-2@4x.png";
        break;
      case "312-350": case "312-730":
        operatore = "Triangle Communication System Inc.";
        img = "https://www.itstriangle.com/images/trianglelogosvg.svg";
        break;
      case "312-490":
        operatore = "TrustComm  Inc.";
        img = "https://files.spazioweb.it/3e/49/3e49cbf5-3f07-44a5-a51e-e03bc9cd4781.png";
        break;
      case "310-860":
        operatore = "TX RSA 15B2  LP";
        img = "";
        break;
      case "310-570":
        operatore = "TX-10  LLC and Central Louisiana Cellular  LLC (MTPCS)";
        img = "";
        break;
      case "310-390":
        operatore = "TX-11 Acquisition  LLC";
        img = "";
        break;
      case "310-940":
        operatore = "Tyntec Inc.";
        img = "https://www.tyntec.com/themes/custom/tyntec/image/tyntec_logo_large.svg";
        break;
      case "310-66": case "310-730": case "311-220": case "311-580":
        operatore = "U.S. Cellular";
        img = "https://it.wikipedia.org/wiki/File:Uscellular_logo20.png";
        break;
      case "310-960":
        operatore = "UBET Wireless";
        img = "";
        break;
      case "311-860": case "312-290":
        operatore = "Uintah Basin Electronic Telecommunications";
        img = "";
        break;
      case "310-20":
        operatore = "Union Telephone Company";
        img = "https://uniontel.net/wp-content/uploads/2020/07/Union-Tel-logo.png";
        break;
      case "311-650":
        operatore = "United Wireless";
        img = "https://getunited.com/wp-content/uploads/2022/01/United-Logo.png";
        break;
      case "313-230":
        operatore = "Velocity Communications Inc.";
        img = "https://www.velotech.net/images/logo.png";
        break;
      case "310-4": case "310-5": case "310-6": case "310-10": case "310-12": case "310-13": case "310-350": case "310-590": case "310-820": case "310-890": case "310-910": case "311-12": case "311-110": case "311-270": case "311-271": case "311-272":
      case "311-273": case "311-274": case "311-275": case "311-276": case "311-277": case "311-278": case "311-279": case "311-280": case "311-281": case "311-282": case "311-283": case "311-284": case "311-285": case "311-286": case "311-287": case "311-288":
      case "311-289": case "311-390": case "311-480": case "311-481": case "311-482": case "311-483": case "311-484": case "311-485": case "311-486": case "311-487": case "311-488": case "311-489": case "311-590": case "312-770": case "310-450": case "310-740":
        operatore = "Verizon Communications";
        img = "https://upload.wikimedia.org/wikipedia/commons/8/81/Verizon_2015_logo_-vector.svg";
        break;
      case "311-470":
        operatore = "Vitelcom Cellular Inc.";
        img = "";
        break;
      case "313-90":
        operatore = "Vivint Wireless  Inc.";
        img = "";
        break;
      case "312-200":
        operatore = "Voyager Mobility LLC";
        img = "https://images.squarespace-cdn.com/content/v1/617031493dc8bb165d9600aa/339c2053-c859-4cfc-bfbf-795e0d88878e/VGM%2BNew%2Blogo%2Bgreen%2Bno%2Btext.png?format=1500w";
        break;
      case "311-990":
        operatore = "VTel Wireless";
        img = "https://www.vtelwireless.com/wp-content/uploads/2018/09/vtel-logo.png";
        break;
      case "310-400": case "310-480": case "311-120": case "311-250":
        operatore = "Wave Runner LLC";
        img = "";
        break;
      case "312-940":
        operatore = "Webformix";
        img = "https://www.webformix.com/wp-content/uploads/2021/05/clean-horizonal-logo-small.png";
        break;
      case "312-360":
        operatore = "Wes-Tex Telecommunications  Ltd.";
        img = "https://westex.coop/wp-content/uploads/2020/09/logo-160x62.png";
        break;
      case "310-180":
        operatore = "West Central Wireless";
        img = "https://www.westcentral.com/images/West-Central.png";
        break;
      case "311-150":
        operatore = "Wilkes Cellular";
        img = "";
        break;
      case "310-330":
      case "312-700":
        operatore = "Wireless Partners  LLC";
        img = "https://www.wireless-partnersllc.com/wp-content/themes/wireless_partners_theme/assets/images/wireless-partners-logo.png";
        break;
      case "312-510":
        operatore = "WUE Inc.";
        img = "";
        break;
      case "310-660":
        operatore = "";
        img = "";
        break;
      default:
        operatore = "";
        img = "";
    }
    return new OperatoriTel(operatore, img);
  }

  private static cellH(mcc: string, mnc: string, val1: string, val2: string): string {
    return (String)(mcc + "-" + mnc + "-" + val1 + "-" + val2);
  }

  private static parseHuaAtCommand(atComm: string, scannum: number, location: number[]): IScanResult | null | undefined {
    if (atComm.indexOf("ERROR") >= 0) {
      return null;
    }
    const cell = new ScanResultH(scannum, "", 0, location);
    return cell;
  }

  private static parseSimAtCommand(atComm: string, scannum: number, location: number[]): IScanResult | null | undefined {
    const a = atComm.replaceAll("+CPSI: ", ";");
    const arr = a.split(";");
    if (arr.length > 0) {
      if (arr.length == 3) {
        const lte = arr[1].split(",");
        const operator = this.getOperatore(lte[2]);
        const frequency = this.getFrequency(lte[0], (Number)(lte[7]));
        const cellLTE = new ScanResultS_lte(scannum, lte[0], lte[1], lte[2], lte[3], lte[4], lte[6], lte[11], operator, frequency);
        cellLTE.setCellCode();
        cellLTE.setCellCodeSecond();
        const nsa = arr[2].split(",");
        const cell5G = new ScanResultS_5GNSA(scannum, nsa[0], nsa[1], nsa[2], nsa[4], cellLTE);
        return cell5G;
      }
      else if (arr.length == 2) {
        if (arr[1].indexOf("LTE") >= 0) {
          const lte = arr[1].split(",");
          const operator = this.getOperatore(lte[2]);
          const frequency = this.getFrequency(lte[0], (Number)(lte[7]));
          const cellLTE = new ScanResultS_lte(scannum, lte[0], lte[1], lte[2], lte[3], lte[4], lte[6], lte[11], operator, frequency);
          cellLTE.setCellCode();
          cellLTE.setCellCodeSecond();
          return cellLTE;
        } else if (arr[1].indexOf("WCDMA") >= 0) {
          const wcdma = arr[1].split(",");
          const operator = this.getOperatore(wcdma[2]);
          const cellwcdma = new ScanResultS_wcdma(scannum, wcdma[0], wcdma[1], wcdma[2], wcdma[3], wcdma[4], wcdma[5], operator, wcdma[7]);
          cellwcdma.setCellCode();
          return cellwcdma;
        } else if (arr[1].indexOf("NR5G_SA") >= 0) {
          const sa = arr[1].split(",");
          const operator = this.getOperatore(sa[2]);
          const frequency = this.getFrequency(sa[0], (Number)(sa[7]));
          const cellSA = new ScanResultS_5GSA(scannum, sa[0], sa[1], sa[2], sa[4], sa[6], sa[10], operator, frequency);
          cellSA.setCellCode();
          return cellSA;
        } else if (arr[1].indexOf("NO") >= 0) {
          const no = arr[1].split(",");
          const operator = this.getOperatore("");
          const cellNo = new ScanResultS_noService(scannum, no[0], no[1], operator);
          return cellNo;
        }
      }
    }
    return null;
  }

  private static parseGPSCommand(atComm: string, scannum: number): IScanResult | null | undefined {
    atComm = atComm.replace("+CGPSINFO: ", "");
    if (atComm.indexOf(",,,,,") >= 0 || atComm.indexOf("error") >= 0) {
      $BTSTracker.value.gpsBTS = 0;
      return null;
    } else {
      $BTSTracker.value.gpsBTS = 1;
      const arr = atComm.split(",");
      const gps = new ScanGPS(scannum, arr[6]);
      gps.setConversion(arr[0], arr[1], 1);
      gps.setConversion(arr[2], arr[3], 2);
      return gps;
    }

  }

}

export class CellRecord {
  id?= undefined;

  date = "";

  // gelocation
  latitude = 0;
  longitude = 0;
  altitude = 0;

  accuracy = 0;
  speed = 0;
  course = 0;
  satellites_in_view = 0;

  // tower identification
  mcc = ""; // cgi
  mnc = "";
  lac = 0;
  cid = 0; //
  c1 = 0;
  c2 = 0;
  arfcn = 0;
  band = "";
  ta = 0;
  eci = 0;
  cell_survey_id = 0;
  is_genuine = false;
  bsic = 0;
  psc = 0;
  pci = 0;
  tac = 0;
  e_node_b = 0;
  power = 0;
  cell_mode = 0;
  tech = 0;
  fileName = "";

  toJson(): string {
    return JSON.stringify(this);
  }

  GetCGI(): string {
    return `${this.mcc}-${this.mnc}-${this.lac}-${this.cid}`;
  }

  GetTech(): string {
    switch (this.tech) {
      case 0:
        return "GSM";
      case 1:
        return "UMTS";
      case 2:
        return "UNKNOWN_2";
      case 3:
        return "LTE";
      case 4:
        return "AutoConnection5G";
      default:
        return "UNKNOWN";
    }
  }

  static fromJson(json: string): CellRecord {
    try {
      const j = JSON.parse(json);
      return Object.assign(new CellRecord(), j);
    } catch (error) { // just for logging the gson
      console.warn("Error parsing json: " + json);
      throw error;
    }
  }
  static fromCsv(csvString: string): CellRecord {
    const csv = csvString.split(";");
    const cell = new CellRecord();
    cell.mcc = csv[0];
    cell.mnc = csv[1];
    cell.lac = parseIntOrZero(csv[2]);
    cell.cid = parseIntOrZero(csv[3]);
    cell.c1 = parseIntOrZero(csv[4]);
    cell.c2 = parseIntOrZero(csv[5]);
    cell.arfcn = parseIntOrZero(csv[6]);
    cell.band = csv[7];
    cell.ta = parseIntOrZero(csv[8]);
    cell.eci = parseIntOrZero(csv[9]);
    cell.cell_survey_id = parseIntOrZero(csv[10]);
    cell.is_genuine = csv[11] === "true";
    cell.bsic = parseIntOrZero(csv[12]);
    cell.psc = parseIntOrZero(csv[13]);
    cell.pci = parseIntOrZero(csv[14]);
    cell.tac = parseIntOrZero(csv[15]);
    cell.e_node_b = parseIntOrZero(csv[16]);
    cell.power = parseIntOrZero(csv[17]);
    cell.cell_mode = parseIntOrZero(csv[18]);
    cell.tech = parseIntOrZero(csv[19]);
    cell.latitude = parseFloatOrZero(csv[20]) / 10000000;
    cell.longitude = parseFloatOrZero(csv[21]) / 10000000;
    cell.altitude = parseFloatOrZero(csv[22]) / 10000000;
    cell.speed = parseFloatOrZero(csv[23]);
    cell.course = parseFloatOrZero(csv[24]);
    cell.satellites_in_view = parseIntOrZero(csv[25]);
    cell.date = csv[26];
    return cell;
  }
  static toCsv(cr: CellRecord): string {
    return `${cr.mcc};${cr.mnc};${cr.lac};${cr.cid};${cr.c1};${cr.c2};${cr.arfcn};${cr.band};${cr.ta};${cr.eci};${cr.cell_survey_id};${cr.is_genuine};${cr.bsic};${cr.psc};${cr.pci};${cr.tac};${cr.e_node_b};${cr.power};${cr.cell_mode};${cr.tech};${cr.latitude};${cr.longitude};${cr.altitude};${cr.speed};${cr.course};${cr.satellites_in_view};${cr.date}`;
  }

  static toJson(cr: CellRecord): string {
    return JSON.stringify(cr);
  }
  _toJson(): string {
    return JSON.stringify(this);
  }
}

interface scanWifi {
  name: string;
  signal: number;
  encryp: string;
  mac: string;
}

export class ScanResultWiFi implements IScanResult {
  network: scanWifi[] = [];
  ScanNumber: number;

  constructor() {
    this.ScanNumber = 0;
  }

  addScan(name_: string, signal_: number, encryp_: string, mac_: string, scanNumber: number) {
    this.network.push({
      name: name_,
      signal: signal_,
      encryp: encryp_,
      mac: mac_,
    });
    this.ScanNumber = scanNumber;
  }

}

export class wifiResult {
  static parseWifi(atCommand: string, scannum: number) {
    if (atCommand.indexOf("Couldn't get a wifi connection") >= 0) {
      return false;
    } else {
      atCommand = atCommand.replaceAll(";;", ";");
      const command = atCommand.split(";");
      const wifi = new ScanResultWiFi();
      for (let i = 0; i < command.length; i++) {
        const value = command[i].split(", ");
        if (value[0].length > 0) {
          wifi.addScan(value[0], parseInt(value[1]), value[3], value[2], scannum);
        } 
      }
      console.log(wifi)
      return wifi;
    }
  }
}

function parseFloatOrZero(input: any) {
  const parsed = parseFloat(input);
  return isNaN(parsed) || parsed === undefined ? 0 : parsed;
}

function parseIntOrZero(input: any) {
  const parsed = parseInt(input);
  return isNaN(parsed) || parsed === undefined ? 0 : parsed;
}