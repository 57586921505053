
/// <reference types="web-bluetooth" />
import { Options, Vue } from "vue-class-component";
import Button from "@/components/Button.vue";
import { $BTSTracker, BTSTracker } from "@/model";
import { createToast } from 'mosha-vue-toastify';
import Spinner from "@/components/Spinner.vue";

@Options({
  components: {
    Button,
    Spinner
  },
  data() {
    return {
      btsTracker: $BTSTracker
    };
  },
})
export default class Connect extends Vue {
  btsTracker!: BTSTracker;
  toggle() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.$root.connected = !this.$root.connected;
  }

  async tryConnect() {
    try {
      await this.btsTracker.createConnection();
      // let rsp = await this.btsTracker.downloadFile("AAAAA.txt");
      //rsp.saveToFile("vano.txt");
      //let x = await this.btsTracker.getFolders();
      // //console.log( x);
    } catch (error) {
      //console.log( error);

      let message: string = "" + error;

      if (message !== "NotFoundError: User cancelled the requestDevice() chooser.") {
        createToast(
          {
            title: this.$t("createToast.conn"),
            description: message
          },
          {
            type: 'danger',
            showIcon: true,
            hideProgressBar: true,
          });
      }
      throw error;
    }
  }
}
