import { createApp } from 'vue';
import router from './router';
import App from './App.vue';
import './registerServiceWorker';
import { GDialog } from 'gitart-vue-dialog';
import i18next from 'i18next';
import I18NextVue from 'i18next-vue';
import * as Sentry from '@sentry/vue';
import { ReportingObserver as ReportingObserverIntegration } from "@sentry/integrations";
import LanguageDetector from 'i18next-browser-languagedetector';
import VueMapboxTs from 'vue-mapbox-ts';
import Vue3TouchEvents from 'vue3-touch-events';
import resourcesToBackend from 'i18next-resources-to-backend';
import { i18nextPlugin } from 'translation-check'
//import { BrowserTracing } from '@sentry/tracing';
import { Vue3ProgressPlugin } from '@marcoschulte/vue3-progress';
import VueResizeText from './vue3-resize-text/index';

import { initDatabase } from './jsstore_con';
import { Log, tryUpdateCarriers } from './model';

import('./workers/index');

(
  () => {
    Log.debug("Loading external stylesheets");
    import("./ts/styles").then(() => Log.debug("External stylesheets loaded"));

    //tryUpdateCarriers();
  }
)()

// redirect to https if http
// check if env prod
if (process.env.NODE_ENV === "production") {
  if (window.location.protocol === "http:") {
    //console.lo( "HTTP detected, redirecting to HTTPS");
    window.location.href = window.location.href.replace("http:", "https:");
  }
} else {
  //console.lo( "Running in development mode");
  i18next.use(i18nextPlugin);
}

initDatabase();

i18next
  .use(LanguageDetector)
  .use(resourcesToBackend((language, namespace, callback) => {
    //console.lo( "resourcesToBackend", language, namespace);
    import(`./locales/${language}.json`)
      .then((resources) => {
        callback(null, resources)
      })
      .catch((error) => {
        callback(error, null)
      })
  }))
  .init({
    fallbackLng: 'en',
    supportedLngs: ['en', 'it', 'es', 'cn', 'fr'],
    debug: process.env.NODE_ENV !== "production",

  }, (err, t) => {
    if (err != undefined) {
      Log.error(err);
    }
    return;
  });

const app = createApp(App)
  .component("GDialog", GDialog)
  .use(router)
  .use(Vue3ProgressPlugin)
  .use(VueMapboxTs)
  .use(Vue3TouchEvents, {
    swipeTolerance: 140,
  })
  .use(VueResizeText)
  .use(I18NextVue, {
    i18next: i18next,
    rerenderOn: ['initialized', 'languageChanged', 'loaded'],
  });


if (process.env.NODE_ENV === "development" || window.location.hostname == "bt.devfarm.it") {// env dev or url is bt.devfarm.
  Sentry.init({
    app,
    dsn: "https://fd2a1a7aed1f4e469b782085fd6377da@o1303640.ingest.sentry.io/6542952",
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ["localhost", "bt.devfarm.it", "detect26.securcube.net"],
      }),
      new Sentry.Replay({
        blockAllMedia: false,
        maskAllText: false,
        maskAllInputs: false,
        // inputs with password
        block: ["input[type=password]"],
      }),
      new ReportingObserverIntegration()
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
    logErrors: true,
    trackComponents: true,
    environment: process.env.NODE_ENV,
  });
} else if (process.env.NODE_ENV === "production") {
  Sentry.init({
    app,
    dsn: "https://fd2a1a7aed1f4e469b782085fd6377da@o1303640.ingest.sentry.io/6542952",
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ["localhost", "bt.devfarm.it", "detect26.securcube.net"],
      }),
      new ReportingObserverIntegration()
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
    logErrors: true,
    trackComponents: true,
    environment: process.env.NODE_ENV,
  });

}



app.config.performance = true;


//console.lo( "VER --> " + app.version);
//console.lo( "ENV --> " + process.env.NODE_ENV);
if (document.cookie.includes("**^_^_^_^||scdevmode||^_^_^_^**")) {
  Log.warn("SEMI-DEVMODE IS ENABLED");
}
app.mount("#app");

