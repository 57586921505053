import { Log, $database } from "@/model";
import { settings } from "./Settings";

export interface Carrier {
  id: number | undefined;
  mcc: string,
  mnc: string,
  iso: string,
  country: string,
  country_code: string,
  network: string
}

export interface CarrierCountry {
  id: number | undefined;
  country: string;
}

export async function tryUpdateCarriers() {
  { // fetch countries
    try {
      const resp = await fetch("/countries");
      const json = await resp.json();
      const countries = json.map((country: string) => {
        return {
          country: country
        }
      });
      await $database.importCarrierCountries(countries);
    } catch (e) {
      //Log.error(e);
    }
  }


  let country = settings.country;
  if (settings.country === "") { // get current country
    try {
      const resp = await fetch("https://api.db-ip.com/v2/free/self");
      const json = await resp.json();
      country = json.countryName;
    } catch (e) {
      Log.error(e);
    }
  }

  try {
    let url = "/carriers";
    if (country !== "") {
      settings.country = country;
      url += "/country/" + country;
    }
    //console.log( url);
    const resp = await fetch(url);
    const json = await resp.json();
    const carriers = json as Carrier[];

    await $database.importCarriers(carriers);
  } catch (e) {
    Log.error(e);
  }

}