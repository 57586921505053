import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import StatusView from '../views/StatusView.vue'

export const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'header.device_status',
    component: StatusView
  },
  {
    path: '/scan',
    name: 'header.scan',
    component: () => import('../views/ScanView.vue')
  },
  {
    path: '/search',
    name: 'header.search',
    component:() => import("../views/SearchView.vue")
  },
  {
    path: '/map',
    name: 'header.map',
    component: () => import('../views/MapView.vue')
  },
  {
    path: '/files',
    name: 'header.files',
    component: () => import('../views/FilesView.vue')
  },
  {
    path: '/filesLoaded',
    name: 'header.filesLoaded',
    component: () => import('../views/FilesLoadedView.vue')
  },
  {
    path: '/settings',
    name: 'header.settings',
    component: () => import('../views/SettingsView.vue')
  },
  {
    path: '/APNlist',
    name: 'header.apnList',
    component: () => import('../views/APNlist.vue')
  }
]

// ennv dev
/*if (process.env.NODE_ENV !== 'production' || document.cookie.includes("**^_^_^_^||scdevmode||^_^_^_^**")) {
  routes.push({
    path: '/dev',
    name: 'Dev',
    component: () => import('../views/DevView.vue')
  } as RouteRecordRaw)
}*/

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
