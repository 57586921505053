import { Log } from "@/model";

export function shareOrDownloadBlob(blob: Blob, fileName: string) {
  if (fileName.endsWith(".scscan")) {
    fileName = fileName.substring(0, fileName.length - 7) + ".csv";
  }
  const file = new File([blob], fileName, { type: blob.type });
  if (navigator.canShare && navigator.canShare({ files: [file] }) && fileName.endsWith(".csv")) {
      Log.info("FileUtils.shareOrDownloadBlob - sharemode fname=" + fileName);
      navigator.share({
          text: fileName,
          title: fileName,
          files: [file]
      } as ShareData).catch(error => {
          Log.error("FileUtils.shareOrDownloadBlob - sharemode error=" + error);
          const a = document.createElement('a');
          a.href = URL.createObjectURL(file);
          a.download = fileName;
          a.click();
          a.remove();
      })


  } else {
  Log.info("navigator.share not supported");
  Log.info("FileUtils.shareOrDownloadBlob - savemode fname=" + fileName);
  const a = document.createElement('a');
  a.href = URL.createObjectURL(blob);
  a.download = fileName;
  a.click();
  a.remove();
  }
}

export function downloadBlob (blob: Blob, fileName: string){
  if(fileName.endsWith(".scscan")){
    fileName = fileName.substring(0, fileName.length - 7)+ ".csv";
  }
  const a = document.createElement('a');
  a.href = URL.createObjectURL(blob);
  a.download = fileName;
  a.click();
  a.remove();
}
