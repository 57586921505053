/// <reference types="web-bluetooth" />
import { ref } from "vue";
import { createToast } from 'mosha-vue-toastify';
import { $database } from "@/jsstore_con";
import { CellRecord, IScanResult, ScanResult, ScanResultH, ScanResultS_lte, ScanResultS_5GNSA, ScanResultS_5GSA, ScanResultS_noService, ScanGPS, ScanResultS_wcdma, OperatoriTel, ScanResultS, ScanResultWiFi, wifiResult } from "./CellRecord";
import { shareOrDownloadBlob } from "@/ts/fileutils";
import { downloadBlob } from "@/ts/fileutils";
import { Log } from "./Log";
import { isApple } from "@/ts/platform";
import { mode } from "crypto-js";


class FileBuffer {
  totalParts: number;
  buffer: Array<Uint8Array>;
  fileName: string;
  currentChecksum = ""
  receivedParts = 0;

  constructor(fileName: string, totalParts: number) {
    this.fileName = fileName;
    this.totalParts = totalParts;
    this.buffer = new Array<Uint8Array>(totalParts);
  }

  public addPart(part: DataView) {
    if (this.receivedParts > this.totalParts) {
      throw "FileBuffer.addPart: all parts received";
    }
    this.buffer[this.receivedParts] = new Uint8Array(part.buffer) // don't merge or it would be too cpu & ram intensive
    this.receivedParts++;
  }

  public isComplete(): boolean {
    return this.receivedParts >= this.totalParts;
  }

  public getFileName(): string {
    return this.fileName;
  }

  public getBuffer(): Uint8Array[] {
    return this.buffer;
  }

  public saveToFile(fileName: string) {
    if (!this.isComplete()) throw "FileBuffer.saveToFile: not complete";
    Log.info("FileBuffer.saveToFile: saving file " + fileName);

    const blob = new Blob(this.buffer, { type: "text/csv" });
    //shareOrDownloadBlob(blob, fileName);
    downloadBlob(blob, fileName);
  }
}

class FoldersBuffer {
  totalParts: number;
  receivedParts = 0;
  buffer = "";
  constructor(totalParts: number) {
    this.totalParts = totalParts;
  }
  public addPart(part: string) {
    this.buffer += part;
    this.receivedParts++;
    if (this.receivedParts > this.totalParts) {
      throw "FileBuffer.addPart: all parts received";
    }
    //console.log(part);
  }
  public getBuffer(): string {
    return this.buffer;
  }
  public isComplete(): boolean {
    return this.receivedParts >= this.totalParts;
  }
}

class Command {
  command: Record<string, unknown>;
  commandResolverOk: (a: Record<string, unknown>) => void;
  constructor(command: Record<string, unknown>, commandResolverOk: (a: Record<string, unknown>) => void) {
    this.command = command;
    this.commandResolverOk = commandResolverOk;
  }
}

class ScanMap {
  power = 0;
  tec = "";
  coords: number[] = [];
  constructor(power: number, tec: string, long: number, lat: number) {
    this.power = power;
    this.tec = tec;
    this.coords[0] = long;
    this.coords[1] = lat;
  }
}

interface cellInfo {
  band: string;
  power: number;
}

export class searchCell {
  name = "";
  number = 0;
  type = "";
  constructor(name: string, number: number) {
    this.name = name;
    this.number = number;
  }
}

export class BTSTracker {
  [x: string]: any;

  operator = [
    // italy
    { paese: "Italia", operator: "Vodafone", apn: "web.omnitel.it", user: "", psw: "" },
    { paese: "Italia", operator: "Very Mobile", apn: "Internet.it", user: "", psw: "" },
    { paese: "Italia", operator: "Iliad", apn: "iliad", user: "", psw: "" },
    { paese: "Italia", operator: "Tim", apn: "ibox.tim.it", user: "", psw: "" },
    { paese: "Italia", operator: "Wind", apn: "internet.wind", user: "", psw: "" },
    { paese: "Italia", operator: "3 Italia", apn: "tre.it", user: "", psw: "" },
    { paese: "Italia", operator: "WindTre", apn: "internet.it", user: "", psw: "" },
    { paese: "Italia", operator: "Ho Mobile", apn: "internet.ho-mobile.it", user: "", psw: "" },
    { paese: "Italia", operator: "PosteMobile", apn: "wap.postemobile.it", user: "", psw: "" },
    { paese: "Italia", operator: "Fastweb Mobile", apn: "apn.fastweb.it", user: "", psw: "" },
    { paese: "Italia", operator: "Kena Mobile", apn: "web.kenamobile.it", user: "", psw: "" },
    { paese: "Italia", operator: "LycaMobile", apn: "data.lycamobile.it", user: "", psw: "" },
    { paese: "Italia", operator: "CoopVoce", apn: "web.coopvoce.it", user: "", psw: "" },
    { paese: "Italia", operator: "Rabona Mobile", apn: "rabona.it", user: "", psw: "" },
    { paese: "Italia", operator: "Tiscali Mobile", apn: "tiscalimobileinternet", user: "", psw: "" },
    { paese: "Italia", operator: "1Mobile", apn: "web.unomobile.it", user: "", psw: "" },
    { paese: "Italia", operator: "Spusu Mobile", apn: "web", user: "", psw: "" },
    { paese: "Italia", operator: "Feder Mobile", apn: "web.federmobile.it", user: "", psw: "" },
    { paese: "Italia", operator: "Green Mobile", apn: "internet.btitalia.it", user: "", psw: "" },
    { paese: "Italia", operator: "Noitel", apn: "web.noitel.it", user: "", psw: "" },
    { paese: "Italia", operator: "Ntmobile", apn: "internet.ntmobile.it", user: "", psw: "" },
    { paese: "Italia", operator: "Optima Mobile", apn: "web.optima.it", user: "", psw: "" },
    { paese: "Italia", operator: "SiJa Mobile", apn: "sija.it", user: "", psw: "" },
    { paese: "Italia", operator: "Vianova Mobile", apn: "apn.vianova.it", user: "", psw: "" },
    { paese: "Italia", operator: "Digi Mobile", apn: "digi.mobile", user: "", psw: "" },
    // spain
    { paese: "España", operator: "Movistar 1", apn: "movistar.es", user: "MOVISTAR", psw: "MOVISTAR" },
    { paese: "España", operator: "Movistar 2", apn: "telefonica.es", user: "TELEFONICA", psw: "TELEFONICA" },
    { paese: "España", operator: "Vodafone", apn: "airtelwap.es", user: "wap@wap", psw: "wap125" },
    { paese: "España", operator: "Orange", apn: "orangeworld", user: "orange", psw: "orange" },
    { paese: "España", operator: "Yoigo", apn: "internet", user: "", psw: "" },
    { paese: "España", operator: "ONO", apn: "internet.ono.com", user: "", psw: "" },
    { paese: "España", operator: "Jazztel", apn: "jazzinternet", user: "", psw: "" },
    { paese: "España", operator: "Simyo", apn: "gprs - service.com", user: "", psw: "" },
    { paese: "España", operator: "Pepephone", apn: "gprsmov.pepephone.com", user: "", psw: "" },
    { paese: "España", operator: "MasMovil", apn: "internetmas", user: "", psw: "" },
    { paese: "España", operator: "Amena", apn: "orangeworld", user: "orange", psw: "orange" },
    { paese: "España", operator: "R Móvil / MobilR", apn: "internet.mundo - r.com", user: "", psw: "" },
    { paese: "España", operator: "Tuenti", apn: "tuenti.com", user: "tuenti", psw: "tuenti" },
    { paese: "España", operator: "Lebara", apn: "gprsmov.lebaramobile.es", user: "", psw: "" },
    { paese: "España", operator: "Euskaltel", apn: "internet.euskaltel.mobi", user: "CLIENTE", psw: "EUSKALTEL" },
    { paese: "España", operator: "LycaMobile(Lyca)", apn: "data.lycamobile.es", user: "lmes", psw: "plus" },
    { paese: "España", operator: "Eroski", apn: "gprs.eroskimovil.es", user: "wap@wap", psw: "wap125" },
    { paese: "España", operator: "Carrefour", apn: "CARREFOURINTERNET", user: "", psw: "" },
    { paese: "España", operator: "Happy Móvil", apn: "internettph", user: "", psw: "" },
    { paese: "España", operator: "LlamaYa", apn: "moreinternet", user: "", psw: "" },
    { paese: "España", operator: "DigiMobil", apn: "internet.digimobil.es", user: "digi", psw: "digi" },
    { paese: "España", operator: "TeleCable", apn: "internet.telecable.es", user: "telecable", psw: "telecable" },
    { paese: "España", operator: "Lowi.es", apn: "lowi.private.omv.es", user: "", psw: "" },
    { paese: "España", operator: "Freedompop", apn: "internetmas", user: "", psw: "" },
    { paese: "España", operator: "Ocean's", apn: "oceans.es", psw: "OCEANS", user: "OCEANS" },
    { paese: "España", operator: "República móvil", apn: "orangeworld", user: "orange", psw: "orange" },
    { paese: "España", operator: "Móvil DIA", apn: "diainternet", user: "", psw: "" },
    { paese: "España", operator: "BT Móvil", apn: "internet.bt.es", user: "bt@internet", psw: "internet" },
    { paese: "España", operator: "O2", apn: "telefonica.es", user: "telefonica", psw: "telefonica" },
    { paese: "España", operator: "TeleCartagena", apn: "inet.es", user: "ONLYCABLE", psw: "" },
    { paese: "España", operator: "SUOP", apn: "inet.es", user: "", psw: "" },
    { paese: "España", operator: "CableMovil", apn: "internet", user: "", psw: "" },
    { paese: "España", operator: "eticom", apn: "internetmas", user: "", psw: "" },
    { paese: "España", operator: "ION mobile", apn: "inet.es", user: "", psw: "" },
    { paese: "España", operator: "jet net", apn: "inet.es", user: "", psw: "" },
    { paese: "España", operator: "Parlem", apn: "internetmas", user: "", psw: "" },
    { paese: "España", operator: "RACCTel", apn: "internet.racc.net", user: "CLIENTERACC", psw: "RACC" },
    { paese: "España", operator: "Hits Mobile", apn: "tel.hitsmobile.es", user: "", psw: "" },
    { paese: "España", operator: "FiNetwork", apn: "fi.omv.es", user: "", psw: "" },
    { paese: "España", operator: "FibraCat", apn: "fibracat.cat", user: "", psw: "" },
    //mexico
    { paese: "México", operator: "Movistar", apn: "internet.movistar.mx", user: "movistar", psw: "movistar" },
    { paese: "México", operator: "Iusacell", apn: "web.iusacellgsm.mx", user: "Iusacellgsm", psw: "iusacellgsm" },
    { paese: "México", operator: "Virgin Mobile", apn: "internet.virginmobile.mx", user: "", psw: "" },
    { paese: "México", operator: "Tuenti", apn: "internet.tuenti.mx", user: "", psw: "" },
    { paese: "México", operator: "Telcel", apn: "internet.itelcel.com", user: "webgprs", psw: "webgprs2002" },
    //usa
    { paese: "USA", operator: "AT&T", apn: "NRPHONE", user: "", psw: "" },
    { paese: "USA", operator: "Boost_Mobile", apn: "Boost_Mobile", user: "", psw: "" },
    { paese: "USA", operator: "T-MOBILE", apn: "fast.tmobile.com", user: "", psw: "" },
    { paese: "USA", operator: "USCC", apn: "internet", user: "yourMSID@uscc.net", psw: "your MSID" },
    { paese: "USA", operator: "Verizon", apn: "vzwinternet", user: "", psw: "" },
    { paese: "USA", operator: "Sprint", apn: "cinet.spcs", user: "", psw: "" },
    { paese: "USA", operator: "Virgin Mobile", apn: "goto.virginmobile.uk", user: "", psw: "" },
    { paese: "USA", operator: "Blue Sky", apn: "4g.bluesky", user: "", psw: "" },
    { paese: "USA", operator: "Pioneer Cellular", apn: "CdmaNai", user: "", psw: "" },
    { paese: "USA", operator: "Limitless Mobile LLC", apn: "internet", user: "", psw: "" },
    //czech 
    { paese: "Czech Republic", operator: "Vodafone", apn: "internet", user: "", psw: "" },
    { paese: "Czech Republic", operator: "o2", apn: "internet", user: "", psw: "" },
    { paese: "Czech Republic", operator: "T-Mobile", apn: "internet.t-mobile.cz", user: "", psw: "" },
  ];

  // bts
  lastVersionF = "1.1.0.23220";
  connected = false;
  connecting = false;
  // battery 
  batteryPercentage = 0;
  mediaBatteria = 0;
  millivolts = 0;
  currVoltage = 0;
  usbConnected = 0;
  acok = 0;

  private static stringEncoder = new TextEncoder();
  private static stringDecoder = new TextDecoder("utf-8");

  isScanning = false;
  startScan = false;
  startScanSearch = true;
  tech = ['LTE', 'UMTS', 'GSM', ' '];
  technologies = [' '];
  modeS = '2';
  autoConnection = '5G';
  fallback = true;
  colonne = ['cell', 'freq', 'down_up', 'power', 'oper'];
  lista = "1"; // visualizzazione files "0"-> icone; "1"-> lista
  fileBuffer: FileBuffer | undefined;
  folderBuffer: FoldersBuffer | undefined;
  // time and date da get_info
  deviceTime = "";
  deviceData = "";
  deviceDataTime = "";
  caricamentoSD = true;

  fileName = ""; // scan file
  lastFileName = "";
  folder = "scan";
  timeStartScan = ""; // start time of scan
  gpsBTS = 0; // gpsBTS not found = 0, connected = 1
  restart = false;

  // list for map view 
  listCoordScansioni: ScanMap[] = [];
  listCoordPosizioni: number[][] = [];

  // cell results for AutoConnection
  cellLTE: ScanResultS_lte | undefined;
  cell5GNSA: ScanResultS_5GNSA | undefined;
  cell5GSA: ScanResultS_5GSA | undefined;
  cellNoSer: ScanResultS_noService | undefined;
  cellLTE2: ScanResultS_lte | undefined;
  cell5GNSA2: ScanResultS_5GNSA | undefined;
  cell5GSA2: ScanResultS_5GSA | undefined;
  cellNoSer2: ScanResultS_noService | undefined;
  cellwcdma: ScanResultS_wcdma | undefined;
  cellwcdma2: ScanResultS_wcdma | undefined;
  // cell results for LTE - UMTS - GSM
  cellHlte: ScanResultH | undefined;
  cellHumts: ScanResultH | undefined;
  cellHgsm: ScanResultH | undefined;
  cellH: ScanResultH | undefined;
  // wifi
  wifiScan: ScanResultWiFi | undefined;
  errorWifi = false;

  closeConnected = false;
  closeAvailable = false;
  closeLTE = false;
  closeUMTS = false;
  closeGSM = false;
  closeWifi = false;

  appLocation: GeolocationCoordinates | undefined = undefined;

  dataCharacteristic: BluetoothRemoteGATTCharacteristic | undefined = undefined;
  statusCharacteristic: BluetoothRemoteGATTCharacteristic | undefined = undefined;
  server: BluetoothRemoteGATTServer | undefined = undefined;
  commandSendChara: BluetoothRemoteGATTCharacteristic | undefined = undefined;
  commandFeedbackChara: BluetoothRemoteGATTCharacteristic | undefined = undefined;
  scanAResultChara: BluetoothRemoteGATTCharacteristic | undefined = undefined;
  scanBResultChara: BluetoothRemoteGATTCharacteristic | undefined = undefined;
  scanFeedbackChara: BluetoothRemoteGATTCharacteristic | undefined = undefined;

  // file characteristics
  fileManagerCommandFeedbackChara: BluetoothRemoteGATTCharacteristic | undefined = undefined; // write
  fileManagerReceiverChara: BluetoothRemoteGATTCharacteristic | undefined = undefined; // read
  fileManagerChecksumCharacteristic: BluetoothRemoteGATTCharacteristic | undefined = undefined; // read
  fileBufferPromiseResolver: undefined | ((a: FileBuffer) => void);
  folderBufferPromiseResolver: undefined | ((a: FoldersBuffer) => void);

  // count of scan results
  scanResults = 0;
  scanResultsS = 0;
  scanResultsH = 0;
  netResultL = 0;
  netResultG = 0;
  netResultU = 0;
  timeLte = "";
  timeUmts = "";
  timeGsm = "";
  scanResultWifi = 0;

  commands: Command[] = [];
  currentCommandObject: Command | undefined = undefined;
  availableAt = new Date().getTime();
  device: BluetoothDevice | undefined;

  // gps
  tipo = 0; // type of gps
  latitude = 0.0;
  longitude = 0.0;
  altitude = 0.0;
  speed = 0.0;
  course = 0.0;
  accuracy = 0.0;
  link = "https://google.com/maps/@" + this.latitude + "," + this.longitude;
  // sd card
  valid = 0;
  sdCardError = false;
  sdCardTotalSize = 0;
  sdCardUsedSize = 0;
  updateSD = false;
  // sim 
  numSim = 0;
  simH = "";
  simS1 = "";
  imsi1_old = "";
  simS2 = "";
  imsi2_old = "";
  checkimsi = true;
  pin1 = "";
  pin2 = "";
  // bts info
  serial = "";
  version = "";
  init = 0;
  bootSeconds = 0;
  perc = 0;
  resultInit = "";
  modA = 0;
  modB = 0;

  datetime = "";

  errorOta = "";
  updateStart = false;
  // state of cell results
  stateLTE = 0;
  stateNO = 0;
  state5GNSA = 0;
  state5GSA = 0;
  stateLTE2 = 0;
  stateNO2 = 0;
  state5GNSA2 = 0;
  state5GSA2 = 0;
  stateWcdma = 0;
  stateWcdma2 = 0;
  // slot of sim
  slot1 = false;
  slot2 = false;

  staticName = false;
  searchName = "";

  sendLocationToBtsTrackerTimeoutID: number | undefined;

  aops: string[] = [];
  cellList = "";
  interestedCell: searchCell[] = [];
  totalInterestedCell = 0;
  connectedCell = true;
  availableCell = true;
  wifi = true;
  reloadFolder = false;
  checkAccuracy = false;

  lastPosition: GeolocationCoordinates | undefined;

  apnSettings = false;
  ftpSettings = false;
  apnLauched = false;
  ftpLauched = false;
  apn1 = "";
  userApn1 = "";
  passApn1 = "";
  apn2 = "";
  userApn2 = "";
  passApn2 = "";
  ftp = "";
  userFtp = "";
  passFtp = "";
  staticInfo = false;
  enableapn = false;
  sceltaSim = '1';
  reportUpload = false;
  differentSim1 = false;
  differentSim2 = false;

  searchAPN = "";
  selectAPN1 = 0;
  selectAPN2 = 0;

  restart_hua = false;
  modem = 1;
  checkGeolocation = true;

  constructor() {

    const anyNav: any = navigator
    if ('wakeLock' in navigator) {
      try {
        anyNav["wakeLock"].request("screen");
      } catch (error) {
        console.error(error);
      }
    }

    setInterval(async () => {

      if (this.connected && this.commandSendChara && !this.currentCommandObject) {
        if (this.commands.length > 0) {
          this.currentCommandObject = this.commands.shift();
          try {
            await this.commandSendChara.writeValue(BTSTracker.stringEncoder.encode(JSON.stringify(this.currentCommandObject?.command) + '\0')); // we put a null byte at the end to make sure the string is terminated otherwise it could cause problems in the firmware
          } catch (error) {
            Log.error(error);
            Log.error(BTSTracker.stringEncoder.encode(JSON.stringify(this.currentCommandObject?.command) + '\0'));
          }
        } else {
          if (new Date().getTime() - this.availableAt >= 20000) {
            this.availableAt = new Date().getTime();
            setTimeout(() => {
              try {
                this.updateInfos().catch(err => {
                  Log.error(err);
                }).then(() => this.availableAt = new Date().getTime());
              } catch (error) {
                Log.error(error);
              }
            }, 500)
          }
        }
      }
    }, 100);
    this.deviceTime = "";
  }

  sendLocationToBtsTracker(geoLoc: GeolocationCoordinates): void {
    // quando si apre il browser partono un sacco di richieste, per evitare un sacco di chiamate
    // metto un timer con un leggero ritardo. In questo modo le chiamate troppo ravvicinate vengono 
    this._sendLocationToBtsTracker(geoLoc);
  }

  _sendLocationToBtsTracker(geoLoc: GeolocationCoordinates): void {

    if (typeof this.sendLocationToBtsTrackerTimeoutID === 'number') {
      clearTimeout(this.sendLocationToBtsTrackerTimeoutID);
    }


    this.sendLocationToBtsTrackerTimeoutID = setTimeout(() => {
      //console.log("update_gps");

      this.sendCommand("update_gps", {
        "latitude": geoLoc.latitude.toFixed(7),
        "longitude": geoLoc.longitude.toFixed(7),
        "accuracy": geoLoc.accuracy.toFixed(3),
        "speed": geoLoc.speed ? geoLoc.speed.toFixed(3) : null,
        "altitude": geoLoc.altitude ? geoLoc.altitude.toFixed(3) : null,
        "altitudeAccuracy": geoLoc.altitudeAccuracy ? geoLoc.altitudeAccuracy.toFixed(3) : null,
        "heading": geoLoc.heading ? geoLoc.heading.toFixed(3) : null
      });

      this.sendLocationToBtsTrackerTimeoutID = undefined;

    }, 5000);

  }

  public async createConnection() {
    if (!navigator.bluetooth) throw "Unable to use Bluetooth.<br/>Please visit bt-info.devfarm.it for more information.";
    if (this.connected) return;
    this.connecting = true;

    this.commands = [];
    this.deviceTime = "";
    this.isScanning = false;
    this.fileBufferPromiseResolver = undefined;
    this.fileBuffer = undefined;
    this.scanResultsS = 0;
    this.scanResultsH = 0;
    this.folderBuffer = undefined;
    this.folderBufferPromiseResolver = undefined;
    this.currentCommandObject = undefined;


    //console.log("Requesting Bluetooth Device...");

    // serach the device
    let dvc: BluetoothDevice | undefined = undefined;
    try {
      let rqdvopt = {} as RequestDeviceOptions;
      if (isApple()) { // fix blueify not fiding devices
        rqdvopt = {
          acceptAllDevices: true
        } as RequestDeviceOptions;
        Log.warn("Apple device detected, using acceptAllDevices");
      } else {
        rqdvopt = {
          filters: [
            {
              services: ["3cfee5c8-d5cf-41d2-8b10-23c5503c4fe5"]
            }, {
              manufacturerData: [{
                companyIdentifier: 0xECCE,
                dataPrefix: new Uint8Array([0x73, 0x20, 0x65, 0x20, 0x63, 0x20, 0x75, 0x20, 0x72, 0x20, 0x63, 0x20, 0x75, 0x20, 0x62, 0x20, 0x65])
              }]
            }],
        } as RequestDeviceOptions;
      }

      rqdvopt.optionalServices = ['3cfee5c8-d5cf-41d2-8b10-23c5503c4fe5', "05674b70-ebda-11ec-8ea0-0242ac120002"];



      dvc = await navigator.bluetooth.requestDevice(rqdvopt);
      this.device = dvc;
    } catch (error) {
      this.connecting = false;
      throw error;
    }



    //console.log("Connecting to GATT Server...");

    if (dvc && dvc.gatt) {
      const srv = await dvc.gatt.connect();
      this.server = srv;

      // command service
      //console.log("Getting command service...");
      const commandService = await srv?.getPrimaryService('3cfee5c8-d5cf-41d2-8b10-23c5503c4fe5');

      // check if the service is available
      if (!commandService) throw "No command service";
      //console.log("getting command characteristics...");
      const commandSendChara = await commandService.getCharacteristic('00000000-0000-1000-8000-00805f9b34fb');
      const commandFeedbackChara = await commandService.getCharacteristic('00000001-0000-1000-8000-00805f9b34fb');
      const scanAResultChara = await commandService.getCharacteristic('00000002-0000-1000-8000-00805f9b34fb');  // modulo 4G
      const scanBResultChara = await commandService.getCharacteristic('00000004-0000-1000-8000-00805f9b34fb');  // modulo 5G
      const scanFeedbackChara = await commandService.getCharacteristic('00000003-0000-1000-8000-00805f9b34fb');
      // check if all characteristics are available
      if (!commandSendChara || !commandFeedbackChara || !scanAResultChara || !scanFeedbackChara || !scanBResultChara) throw "Characteristics not found(s)"; //

      this.commandSendChara = commandSendChara;
      this.commandFeedbackChara = commandFeedbackChara;
      this.scanAResultChara = scanAResultChara;
      this.scanBResultChara = scanBResultChara;
      this.scanFeedbackChara = scanFeedbackChara;
      // register events & start notifications
      scanAResultChara.addEventListener('characteristicvaluechanged', () => {
        this.handleScanResultA();
      });
      scanBResultChara.addEventListener('characteristicvaluechanged', () => {
        this.handleScanResultB();
      });
      commandFeedbackChara.addEventListener('characteristicvaluechanged', () => {
        this.handleCommandFeedback();
      });
      await commandFeedbackChara.startNotifications();
      await scanAResultChara.startNotifications();
      await scanBResultChara.startNotifications();
      //console.log("OK COMANDI");
      // file manager service
      //console.log("Getting file manager service...");
      const fileManagerService = await srv.getPrimaryService('05674b70-ebda-11ec-8ea0-0242ac120002');
      if (!fileManagerService) throw "No file manager service";
      //console.log("getting file manager characteristics...");
      const fileManagerCommandFeedbackChara = await fileManagerService.getCharacteristic('00000000-0000-1000-8000-00805f9b34fb');
      const fileManagerReceiverChara = await fileManagerService.getCharacteristic('00000001-0000-1000-8000-00805f9b34fb');
      const fileManagerChecksumChara = await fileManagerService.getCharacteristic('00000002-0000-1000-8000-00805f9b34fb');
      if (!fileManagerCommandFeedbackChara || !fileManagerReceiverChara || !fileManagerChecksumChara) throw "Characteristics not found(s)";
      //console.log("OK FILEMANAGERS");

      this.fileManagerCommandFeedbackChara = fileManagerCommandFeedbackChara; // write
      this.fileManagerReceiverChara = fileManagerReceiverChara; // read
      this.fileManagerChecksumCharacteristic = fileManagerChecksumChara; // read

      fileManagerReceiverChara?.addEventListener('characteristicvaluechanged', async () => {
        await this.handleFileManagerReceiver();
      });


      fileManagerChecksumChara.addEventListener('characteristicvaluechanged', async () => { this.handleChecksumFeedback(); });


      // DONT REMOVE THIS AWAIT OR THE GATT SERVER WILL CRASH ON MOBILE DEVICES
      await fileManagerChecksumChara?.startNotifications();
      await fileManagerReceiverChara?.startNotifications();

      //console.log("OK NOTIFICATIONS");


      dvc.addEventListener("gattserverdisconnected", this.onDisconnect);
      this.connecting = false;
      this.connected = true;

      await this.sendCommand("set_time", { "time": Math.trunc(Date.now()) });

      const resp = await this.sendCommand("get_bts_info", {});

      if (resp.ok) {
        console.log(resp);
        const tmp = (resp.msg as string).split(";");
        this.serial = tmp[0];
        this.version = tmp[1];
      }


      await this.updateInfos();
      //console.log("connected");
      if (this.isScanning) {
        this.interestedCell = [];
        if (this.cellList && this.cellList.length > 0) {
          await this.sendCommand("set_cell", { "cell": this.cellList });
          this.cellList = this.cellList.replaceAll(" ", "\n");
          const cell = this.cellList.split("\n");
          for (let i = 0; i < cell.length; i++) {
            if (cell[i].length > 6) {
              this.interestedCell.push(new searchCell(cell[i], 0));
            }
          }
        }
      }
      this.update();

    } else {
      //console.log("Device or GATT server not found.");
    }

  }

  private async update() {
    if (this.version.indexOf(this.lastVersionF) < 0) {
      createToast(
        {
          title: "Update available",
          description: "A new firmware update is available, see the guide",
        }, {
        type: 'warning',
        showIcon: true,
        hideProgressBar: true,
      }
      );
    }
  }

  private async onDisconnect() {
    $BTSTracker.value.connected = false;
    $BTSTracker.value.server = undefined;
    $BTSTracker.value.dataCharacteristic = undefined;
    $BTSTracker.value.statusCharacteristic = undefined;
    createToast(
      {
        title: "Connection Interrupted",
        description: "Bluetooth connection died",
      },
      {
        type: 'danger',
        showIcon: true,
        hideProgressBar: true,
      });
    try {
      this.device?.gatt?.disconnect();
      //console.log("GATT DISCONNECTED");
    } catch (e) {
      console.error(e);
    }
    try {
      this.device?.forget();
      //console.log("Device forgotten");
    } catch (e) {
      console.error(e);
    }
  }


  async handleScanResultA() {
    if (this.isScanning == true) {
      const location = [(Number)($BTSTracker.value.longitude.toFixed(6)), (Number)($BTSTracker.value.latitude.toFixed(6))];
      const l = this.listCoordPosizioni.length;
      if (l == 0) {
        this.listCoordPosizioni.push(location);
      } else if (this.listCoordPosizioni.length >= 1) {
        if ((Number)($BTSTracker.value.longitude.toFixed(6)) != this.listCoordPosizioni[l - 1][0]) {
          this.listCoordPosizioni.push(location);
        } else if ((Number)($BTSTracker.value.latitude.toFixed(6)) != this.listCoordPosizioni[l - 1][1]) {
          this.listCoordPosizioni.push(location);
        }
      } else {
        this.listCoordPosizioni.push(location);
      }
      if (l == 2 && this.listCoordPosizioni[1][0] - this.listCoordPosizioni[0][0] == this.listCoordPosizioni[1][0]) {
        this.listCoordPosizioni.shift();
      }
    }
    try {
      if ($BTSTracker.value.scanAResultChara && $BTSTracker.value.scanAResultChara.value) {
        const str = BTSTracker.stringDecoder.decode($BTSTracker.value.scanAResultChara.value);
        try {
          if (str.startsWith("{")) {
            const jsonData = JSON.parse(str);
            const modem = (Number)(jsonData.ind);
            if (modem == 0) {
              const scannum = (Number)(jsonData.num ?? jsonData.surveyNum); // rinominato dalla versione 
              let atCommand = (String)(jsonData.d ?? jsonData.data);
              const parte = (Number)(jsonData.tp);
              atCommand = atCommand.replaceAll("\r;", ";");
              const result = wifiResult.parseWifi(atCommand, scannum);
              if (result) {
                if (parte == 0) {
                  this.wifiScan = result;
                } else {
                  for (let i = 0; i < result.network.length; i++) {
                    this.wifiScan?.addScan(result.network[i].name, result.network[i].signal, result.network[i].encryp, result.network[i].mac, result.ScanNumber);
                  }
                }
                this.scanResultWifi = scannum;
                this.wifiScan?.network.sort((x,y)=>y.signal-x.signal);
              } else {
                this.errorWifi = true;
              }
              //console.log(this.wifiScan);
            } else {
              // per inizializzazione veloce e immediata
              if (JSON.stringify(jsonData).indexOf("check") >= 0 || JSON.stringify(jsonData).indexOf("count") >= 0) {
                this.firstSendCommand(jsonData);
              } else {
                // per le risposte dei moduli
                const atCommand = (String)(jsonData.d ?? jsonData.data);
                const scannum = (Number)(jsonData.num ?? jsonData.surveyNum); // rinominato dalla versione 
                const modem = (Number)(jsonData.ind);
                // SE MODULO HUAWEI versione nuova [moduli 1,2,3,4,5]
                if (JSON.stringify(jsonData).indexOf("ind") >= 0 && (modem == 1 || modem == 3)) {
                  this.modem = modem;
                  const tp = (String)(jsonData.pt ?? jsonData.tp);
                  const type = (String)(tp.charAt(0));
                  let i = 1;
                  let p = "";
                  for (i = 1; i < tp.length; i++) {
                    p += tp.charAt(i);
                  }
                  const part = (Number)(p);
                  const location = [this.longitude, this.latitude];
                  let result;
                  if (modem == 1) { // H 1,2,3
                    result = ScanResult.parseAtCommandHauwei(atCommand, scannum, part, type, location);
                  } else if (modem == 3) { // H 4,5
                    result = ScanResult.parseAtCommandH2(atCommand, scannum, part, type, location);
                  }
                  if (result) {
                    this.restart_hua = false;
                    if (type.indexOf("G") >= 0) {
                      if (part == 0) {
                        this.cellHgsm = result;
                        this.cellHgsm.time = new Date();
                        this.netResultG++;
                        if (this.isScanning == true && modem == 1) {
                          this.listCoordScansioni.push(new ScanMap((Number)(this.cellHgsm.Cells[0].power), this.cellHgsm.Technology, (Number)(this.cellHgsm.location[0].toFixed(6)), (Number)(this.cellHgsm.location[1].toFixed(6))));
                        }
                      } else {
                        let i = 0;
                        for (i = 0; i < result.Cells.length; i++) {
                          this.cellHgsm?.Cells.push(
                            {
                              cellCode: result.Cells[i].cellCode,
                              band: result.Cells[i].band,
                              arfcn: result.Cells[i].arfcn,
                              power: result.Cells[i].power,
                              operatore: result.Cells[i].operatore,
                              img: result.Cells[i].img,
                              down_frequency: result.Cells[i].down_frequency,
                            }
                          );
                        }
                        this.cellHgsm?.Cells.sort((x, y) => y.power - x.power);
                      }
                    } else if (type.indexOf("U") >= 0) {
                      if (part == 0) {
                        this.cellHumts = result;
                        this.netResultU++;
                        this.cellHumts.time = new Date();
                        if (this.isScanning == true && modem == 1) {
                          this.listCoordScansioni.push(new ScanMap((Number)(this.cellHumts.Cells[0].power), this.cellHumts.Technology, (Number)(this.cellHumts.location[0].toFixed(6)), (Number)(this.cellHumts.location[1].toFixed(6))));
                        }
                      } else {
                        let i = 0;
                        for (i = 0; i < result.Cells.length; i++) {
                          this.cellHumts?.Cells.push(
                            {
                              cellCode: result.Cells[i].cellCode,
                              band: result.Cells[i].band,
                              arfcn: result.Cells[i].arfcn,
                              power: result.Cells[i].power,
                              operatore: result.Cells[i].operatore,
                              img: result.Cells[i].img,
                              down_frequency: result.Cells[i].down_frequency,
                            }
                          );
                        }
                        this.cellHumts?.Cells.sort((x, y) => y.power - x.power);
                      }
                    } else if (type.indexOf("L") >= 0) {
                      if (part == 0) {
                        this.cellHlte = result;
                        this.cellHlte.time = new Date();
                        this.netResultL++;
                        if (this.isScanning == true && modem == 1) {
                          this.listCoordScansioni.push(new ScanMap((Number)(this.cellHlte.Cells[0].power), this.cellHlte.Technology, (Number)(this.cellHlte.location[0].toFixed(6)), (Number)(this.cellHlte.location[1].toFixed(6))));
                        }
                      } else {
                        let i = 0;
                        for (i = 0; i < result.Cells.length; i++) {
                          this.cellHlte?.Cells.push(
                            {
                              cellCode: result.Cells[i].cellCode,
                              band: result.Cells[i].band,
                              arfcn: result.Cells[i].arfcn,
                              power: result.Cells[i].power,
                              operatore: result.Cells[i].operatore,
                              img: result.Cells[i].img,
                              down_frequency: result.Cells[i].down_frequency,
                            }
                          );
                        }
                        this.cellHlte?.Cells.sort((x, y) => y.power - x.power);
                      }
                    }
                    this.scanResultsH = result.ScanNumber;
                    this.cellH = result;
                    for (let j = 0; j < this.cellH.Cells.length; j++) {
                      for (let x = 0; x < this.interestedCell.length; x++) {
                        if (this.cellH.Cells[j].cellCode == this.interestedCell[x].name) {
                          this.interestedCell[x].number++;
                          if (type == 'L') {
                            this.interestedCell[x].type = 'LTE';
                          } else if (type == 'G') {
                            this.interestedCell[x].type = 'GSM';
                          } else if (type == 'U') {
                            this.interestedCell[x].type = 'UMTS';
                          }
                        }
                      }
                    }
                  } else if (atCommand.indexOf("END AOPS") >= 0) {
                    if (type.indexOf("G") >= 0 && this.cellHgsm != undefined) {
                      this.cellHgsm?.Cells.sort((x, y) => y.power - x.power);
                      this.listCoordScansioni.push(new ScanMap((Number)(this.cellHgsm?.Cells[0].power), this.cellHgsm.Technology, (Number)(this.cellHgsm?.location[0].toFixed(6)), (Number)(this.cellHgsm?.location[1].toFixed(6))));
                    } else if (type.indexOf("U") >= 0 && this.cellHumts != undefined) {
                      this.cellHumts?.Cells.sort((x, y) => y.power - x.power);
                      this.listCoordScansioni.push(new ScanMap((Number)(this.cellHumts?.Cells[0].power), this.cellHumts.Technology, (Number)(this.cellHumts?.location[0].toFixed(6)), (Number)(this.cellHumts?.location[1].toFixed(6))));
                    } else if (type.indexOf("L") >= 0 && this.cellHlte != undefined) {
                      this.cellHlte?.Cells.sort((x, y) => y.power - x.power);
                      this.listCoordScansioni.push(new ScanMap((Number)(this.cellHlte?.Cells[0].power), this.cellHlte.Technology, (Number)(this.cellHlte?.location[0].toFixed(6)), (Number)(this.cellHlte?.location[1].toFixed(6))));
                    }
                  }
                }

                let conta = 0;
                for (let i = 0; i < this.interestedCell.length; i++) {
                  conta = conta + this.interestedCell[i].number;
                }
                this.totalInterestedCell = conta;
              }

            }
            this.scanResults = this.scanResultsH + this.scanResultsS + this.scanResultWifi;
          }
          else {
            const record = CellRecord.fromCsv(str);
            if (record) {
              record.fileName = $BTSTracker.value.fileName;
              $database.insertPoint(record);
              $BTSTracker.value.scanResultsS++;
              $BTSTracker.value.scanResultsH++;
              const event = new CustomEvent('new-cell', { detail: record });
              window.dispatchEvent(event);
            }
          }

        } catch (e) {
          ////console.log("scan Feedback Chara A");
          $BTSTracker.value.scanFeedbackChara?.writeValue(new Int32Array([1]));
          ////console.log($BTSTracker.value.scanFeedbackChara);
        }
        $BTSTracker.value.availableAt = new Date().getTime();
      }
    } catch (e) {
      //console.log(e);
    }
  }

  async handleScanResultB() {
    if (this.isScanning && this.startScanSearch == true) {
      if (this.cellList.length != 0) {
        await this.sendCommand("set_cell", { "cell": this.cellList });
        this.cellList = this.cellList.replaceAll(" ", "\n");
        const cell = this.cellList.split("\n");
        for (let i = 0; i < cell.length; i++) {
          this.interestedCell.push(new searchCell(cell[i], 0));
        }
      }
      this.startScanSearch = false;
    }
    const event = new Event('on-scan-result-coming');
    document.dispatchEvent(event);

    try {
      if ($BTSTracker.value.scanBResultChara && $BTSTracker.value.scanBResultChara.value) {
        const str = BTSTracker.stringDecoder.decode($BTSTracker.value.scanBResultChara.value);

        try {
          if (str.startsWith("{")) {
            const jsonData = JSON.parse(str);
            // per inizializzazione veloce e immediata
            if (JSON.stringify(jsonData).indexOf("check") >= 0) {
              $BTSTracker.value.modA = Number(jsonData.mod1);
              $BTSTracker.value.modB = Number(jsonData.mod2);
              $BTSTracker.value.perc = Number(jsonData.perc);
            } else if (JSON.stringify(jsonData).indexOf("count") >= 0) {
              $BTSTracker.value.numSim = (Number)(jsonData.count);
              $BTSTracker.value.simH = (jsonData.a1);
              $BTSTracker.value.simS1 = (jsonData.b1);
              $BTSTracker.value.simS2 = (jsonData.b2);
              $BTSTracker.value.pin1 = (jsonData.p1);
              $BTSTracker.value.pin2 = (jsonData.p2);
              if ($BTSTracker.value.pin1.indexOf("/") >= 0) {
                $BTSTracker.value.pin1 = "";
              }
              if ($BTSTracker.value.pin2.indexOf("/") >= 0) {
                $BTSTracker.value.pin2 = "";
              }
              if ($BTSTracker.value.simS1.length > 0) {
                $BTSTracker.value.slot1 = true;
              } else {
                $BTSTracker.value.slot1 = false;
              }
              if ($BTSTracker.value.simS2.length > 0) {
                $BTSTracker.value.slot2 = true;
              } else {
                $BTSTracker.value.slot2 = false;
              }
              if ($BTSTracker.value.simS1.length == 0) {
                //console.log("non c'è 1");
                this.imsi1_old = "";
                this.apn1 = "";
                this.userApn1 = "";
                this.passApn1 = "";
              }
              if ($BTSTracker.value.simS2.length == 0) {
                //console.log("non c'è 2");
                this.imsi2_old = "";
                this.apn2 = "";
                this.userApn2 = "";
                this.passApn2 = "";
              }
              this.perc = 100;
              this.init = 1;

            } else {
              // per le risposte dei moduli
              const atCommand = (String)(jsonData.d ?? jsonData.data);
              const scannum = (Number)(jsonData.num ?? jsonData.surveyNum); // rinominato dalla versione 
              const modem = (Number)(jsonData.ind);

              // [modem B,C,D]
              let part = "00";
              part = (String)(jsonData.pt ?? jsonData.tp);
              const location = [this.longitude, this.latitude];
              const scanResult = ScanResult.parseFromAtCommand(atCommand, scannum, location);

              if (scanResult) {
                if (scanResult instanceof ScanResultS_5GNSA) {
                  this.scanResultsS = scanResult.ScanNumber;
                  if (part.indexOf("00") >= 0 || part.indexOf("10") >= 0) {
                    this.cell5GNSA = scanResult;
                    this.state5GNSA = 1;
                    this.state5GSA = 0;
                    this.stateLTE = 0;
                    this.stateNO = 0;
                    this.stateWcdma = 0;
                  } else {
                    this.cell5GNSA2 = scanResult;
                    this.state5GNSA2 = 1;
                    this.state5GSA2 = 0;
                    this.stateLTE2 = 0;
                    this.stateWcdma2 = 0;
                    this.stateNO2 = 0;
                  }
                  this.this.checkCell(scanResult.lte.cellcode, 'nsa', scanResult.lte.frequencyBand, scanResult.lte.rsrp);
                  if (scanResult.lte.cellcodeS != undefined) {
                    this.checkCell(scanResult.lte.cellcodeS, 'nsa', scanResult.lte.frequencyBand, scanResult.lte.rsrp);
                  }
                } else if (scanResult instanceof ScanResultS_5GSA) {
                  this.scanResultsS = scanResult.ScanNumber;
                  if (part.indexOf("00") >= 0 || part.indexOf("10") >= 0) {
                    this.cell5GSA = scanResult;
                    this.state5GSA = 1;
                    this.state5GNSA = 0;
                    this.stateLTE = 0;
                    this.stateWcdma = 0;
                    this.stateNO = 0;
                  } else {
                    this.cell5GSA2 = scanResult;
                    this.state5GNSA2 = 0;
                    this.state5GSA2 = 1;
                    this.stateLTE2 = 0;
                    this.stateWcdma2 = 0;
                    this.stateNO2 = 0;
                  }
                  this.checkCell(scanResult.cellcode, 'sa', scanResult.frequencyBand, scanResult.rsrp);
                } else if (scanResult instanceof ScanResultS_lte) {
                  this.scanResultsS = scanResult.ScanNumber;
                  if (part.indexOf("00") >= 0 || part.indexOf("10") >= 0) {
                    this.cellLTE = scanResult;
                    this.stateLTE = 1;
                    this.state5GSA = 0;
                    this.state5GNSA = 0;
                    this.stateNO = 0;
                    this.stateWcdma = 0;
                  } else {
                    this.cellLTE2 = scanResult;
                    this.state5GNSA2 = 0;
                    this.state5GSA2 = 0;
                    this.stateLTE2 = 1;
                    this.stateNO2 = 0;
                    this.stateWcdma2 = 0;
                  }
                  this.checkCell(scanResult.cellcode, 'lte', scanResult.frequencyBand, scanResult.rsrp);
                  if (scanResult.cellcodeS != undefined) {
                    this.checkCell(scanResult.cellcodeS, 'lte', scanResult.frequencyBand, scanResult.rsrp);
                  }
                } else if (scanResult instanceof ScanResultS_noService) {
                  this.scanResultsS = scanResult.ScanNumber;
                  if (part.indexOf("00") >= 0 || part.indexOf("10") >= 0) {
                    this.cellNoSer = scanResult;
                    this.stateNO = 1;
                    this.state5GSA = 0;
                    this.state5GNSA = 0;
                    this.stateWcdma = 0;
                    this.stateLTE = 0;
                  } else {
                    this.cellNoSer2 = scanResult; this.state5GNSA2 = 0;
                    this.state5GSA2 = 0;
                    this.stateLTE2 = 0;
                    this.stateWcdma2 = 0;
                    this.stateNO2 = 1;
                  }
                } else if (scanResult instanceof ScanResultS_wcdma) {
                  this.scanResultsS = scanResult.ScanNumber;
                  if (part.indexOf("00") >= 0 || part.indexOf("10") >= 0) {
                    this.cellwcdma = scanResult;
                    this.stateNO = 0;
                    this.state5GSA = 0;
                    this.state5GNSA = 0;
                    this.stateWcdma = 1;
                    this.stateLTE = 0;
                  } else {
                    this.cellwcdma2 = scanResult;
                    this.state5GNSA2 = 0;
                    this.state5GSA2 = 0;
                    this.stateLTE2 = 0;
                    this.stateWcdma2 = 1;
                    this.stateNO2 = 0;
                  }
                  this.checkCell(scanResult.cellcode, 'wcdma', scanResult.frequencyBand, -1);
                  if (scanResult.cellcodeS != undefined) {
                    this.this.checkCell(scanResult.cellcodeS, 'wcdma', scanResult.frequencyBand, -1);
                  }
                } else if (scanResult instanceof ScanResultH) {
                  this.scanResultsH = scanResult.ScanNumber;
                  if (this.isScanning == true) {
                    this.listCoordScansioni.push(new ScanMap(1, "", (Number)($BTSTracker.value.longitude.toFixed(6)), (Number)($BTSTracker.value.latitude.toFixed(6))));
                  }
                } else if (scanResult instanceof ScanGPS) {
                  this.latitude = scanResult.latitude;
                  this.longitude = scanResult.longitude;
                  this.altitude = Number(scanResult.altitude);
                }
              }
              this.scanResults = this.scanResultsH + this.scanResultsS + this.scanResultWifi;
              let conta = 0;
              for (let i = 0; i < this.interestedCell.length; i++) {
                conta = conta + this.interestedCell[i].number;
              }
              this.totalInterestedCell = conta;
            }
          }
          else {
            const record = CellRecord.fromCsv(str);
            if (record) {
              record.fileName = $BTSTracker.value.fileName;
              $database.insertPoint(record);
              $BTSTracker.value.scanResultsS++;
              $BTSTracker.value.scanResultsH++;
              const event = new CustomEvent('new-cell', { detail: record });
              window.dispatchEvent(event);
            }
          }

        } catch (e) {
          ////console.log("scan Feedback Chara B");
          $BTSTracker.value.scanFeedbackChara?.writeValue(new Int32Array([1]));
          ////console.log($BTSTracker.value.scanFeedbackChara);
        }
        $BTSTracker.value.availableAt = new Date().getTime();
      }
    } catch (e) {
      //console.log(e);
    }
  }

  checkCell(CellCode: string, Technology: string, FreqBand: string, Power: number) {
    CellCode = CellCode.replaceAll(" | ", "");
    for (let i = 0; i < this.interestedCell.length; i++) {
      if (CellCode == this.interestedCell[i].name) {
        this.interestedCell[i].number++;
        this.interestedCell[i].type = "connected " + Technology.toUpperCase();
      }
    }
  }

  handleCommandFeedback() {
    if ($BTSTracker.value.commandFeedbackChara && $BTSTracker.value.commandFeedbackChara.value) {
      const str = BTSTracker.stringDecoder.decode($BTSTracker.value.commandFeedbackChara.value);
      if ($BTSTracker.value.currentCommandObject) {
        try {
          const obj = JSON.parse(str);
          $BTSTracker.value.currentCommandObject.commandResolverOk(obj); // resolve the promise
          if (obj.ok) {
            if (obj.msg === "scan_started") {
              $BTSTracker.value.isScanning = true;
            }
            if (obj.msg === "scan_stopped") {
              $BTSTracker.value.isScanning = false;
            }
          }
        } catch (e) {
          console.error(e);
          console.warn("This is the json with troubles " + str);
        }
        this.currentCommandObject = undefined;

      } else {
        //console.log("Receiving command feedback but no resolver");
      }
    } else {
      //console.log("Receiving command feedback but not waiting for it");
    }
  }

  async updateInfos() {

    const cell: Record<string, any> = await this.sendCommand("get_cell", {});
    if (cell.ok) {
      this.cellList = String(cell.msg.cell);
    }

    if (!this.connected || !this.commandSendChara) return;

    await this.sendCommand("set_time_device", { "time": this.deviceDataTime });


    if (this.appLocation)
      this.sendLocationToBtsTracker(this.appLocation);

    const restart: Record<string, any> = await this.sendCommand("restart", {});
    if (restart && restart.ok == true) {
      this.restart = true;
      this.fileName = String(restart.msg.name);
      const technology = String(restart.msg.type);
      this.technologies = technology.split(',');
      this.modeS = String(restart.msg.mode);
      if (this.technologies.indexOf("AutoConnection5G") >= 0) {
        this.connectedCell = true;
        if (this.modeS == '2' || this.modeS == '54' || this.modeS == '55') {
          this.fallback = true;
        } else {
          this.fallback = false;
        }
        if (this.modeS == '14') {
          this.autoConnection = 'UMTS';
        }
        if (this.modeS == '38' || this.modeS == '54') {
          this.autoConnection = 'LTE';
          this.fallback = true;
        }
        if (this.modeS == '71' || this.modeS == '55') {
          this.autoConnection = '5G';
        }
      } else {
        this.connectedCell = false;
      }
      if (this.technologies.indexOf("LTE") >= 0 || this.technologies.indexOf("UMTS") >= 0 || this.technologies.indexOf("GSM") >= 0) {
        this.availableCell = true;
      } else {
        this.availableCell = false;
      }
      this.isScanning = true;
    }

    const scanning = await this.sendCommand("is_scanning", {}); // ask for the status of the scanner
    const msg = "" + scanning.msg as string;
    console.log(scanning);
    if (scanning.ok && msg.length > 1) {
      const splitted = msg.split(";");
      if (splitted[0] == "scanning" && this.restart == false) {
        this.fileName = splitted[2];
        if (this.fileName.endsWith(".scscan")) {
          this.fileName = this.fileName.substring(0, this.fileName.length - 7);
        }
        this.technologies = [];
        splitted[1].split(",").forEach(tech => {
          this.technologies.push(tech);
        });
        console.log(this.technologies);
        if (this.technologies.indexOf("AutoConnection5G") >= 0) {
          this.connectedCell = true;
        } else {
          this.connectedCell = false;
        }
        this.isScanning = true;
      }
    } else {
      this.isScanning = false;
    }

    const batteryPercentagesjson: Record<string, any> = await this.sendCommand("get_battery_json", {});
    if (batteryPercentagesjson && batteryPercentagesjson.ok == true) {
      $BTSTracker.value.millivolts = Number(batteryPercentagesjson.msg.Millivolts);
      $BTSTracker.value.currVoltage = Number(batteryPercentagesjson.msg.currVoltage);
      $BTSTracker.value.usbConnected = Number(batteryPercentagesjson.msg.usbConnected);
      $BTSTracker.value.acok = Number(batteryPercentagesjson.msg.acok);
      $BTSTracker.value.batteryPercentage = Number(batteryPercentagesjson.msg.level);
      if ($BTSTracker.value.batteryPercentage < 0)
        $BTSTracker.value.batteryPercentage = 0;
      if ($BTSTracker.value.batteryPercentage > 100)
        $BTSTracker.value.batteryPercentage = 100;
      $BTSTracker.value.mediaBatteria = batteryPercentagesjson.msg.media;
      if ($BTSTracker.value.mediaBatteria < 0)
        $BTSTracker.value.mediaBatteria = 0;
      if ($BTSTracker.value.mediaBatteria > 100)
        $BTSTracker.value.mediaBatteria = 100;
    }

    // INFO
    const getinfoBtsJson: Record<string, any> = await this.sendCommand("get_btsinfo_json", {});
    if (getinfoBtsJson && getinfoBtsJson.ok) {
      console.log("Setting bts infooo")

      if (getinfoBtsJson.msg.deviceSerial) {
        $BTSTracker.value.serial = String(getinfoBtsJson.msg.deviceSerial);
        $BTSTracker.value.version = String(getinfoBtsJson.msg.fwVersion);
      }


      // DATE
      $BTSTracker.value.datetime = String(getinfoBtsJson.msg.date);
      const dt = $BTSTracker.value.datetime.split("T");
      $BTSTracker.value.deviceData = dt[0];
      $BTSTracker.value.deviceTime = dt[1];
      const d = $BTSTracker.value.deviceData.split("-");
      const h = $BTSTracker.value.deviceTime.split(":");

      $BTSTracker.value.modA = Number(getinfoBtsJson.msg.mod1);
      $BTSTracker.value.modB = Number(getinfoBtsJson.msg.mod2);

      $BTSTracker.value.numSim = (Number)(getinfoBtsJson.msg.count);
      $BTSTracker.value.simH = (getinfoBtsJson.msg.a1);
      $BTSTracker.value.simS1 = (getinfoBtsJson.msg.b1);
      $BTSTracker.value.simS2 = (getinfoBtsJson.msg.b2);
      $BTSTracker.value.pin1 = (getinfoBtsJson.msg.p1);
      $BTSTracker.value.pin2 = (getinfoBtsJson.msg.p2);
      if ($BTSTracker.value.pin1.indexOf("/") >= 0) {
        $BTSTracker.value.pin1 = "";
      }
      if ($BTSTracker.value.pin2.indexOf("/") >= 0) {
        $BTSTracker.value.pin2 = "";
      }
      if ($BTSTracker.value.simS1.length > 0) {
        $BTSTracker.value.slot1 = true;
      } else {
        $BTSTracker.value.slot1 = false;
      }
      if ($BTSTracker.value.simS2.length > 0) {
        $BTSTracker.value.slot2 = true;
      } else {
        $BTSTracker.value.slot2 = false;
      }

      if (this.perc == 0) {
        this.perc = 20;
      }
    }

    const getinfoJson: Record<string, any> = await this.sendCommand("get_info_json", {});
    if (getinfoJson && getinfoJson.ok == true) {
      $BTSTracker.value.init = Number(getinfoJson.msg.init);

      if ($BTSTracker.value.init == 1) {
        $BTSTracker.value.perc = 100;
      } else {
        $BTSTracker.value.perc = Number(getinfoJson.msg.perc);
      }
      //SD
      if (this.updateSD == false) {
        $BTSTracker.value.sdCardError = Boolean(getinfoJson.msg.error);
        if (!$BTSTracker.value.sdCardError) {
          $BTSTracker.value.sdCardTotalSize = Number(getinfoJson.msg.full);
          $BTSTracker.value.sdCardUsedSize = Number(getinfoJson.msg.used);
        } else {
          $BTSTracker.value.sdCardTotalSize = 0;
          $BTSTracker.value.sdCardUsedSize = 0;
        }

        this.caricamentoSD = false;
      }

    }

    this.updateSettings();

  }

  async updateSDCard() {
    const getsd: Record<string, any> = await this.sendCommand("get_sdCard", {});
    if (getsd && getsd.ok) {
      //SD
      $BTSTracker.value.sdCardError = Boolean(getsd.msg.error);
      $BTSTracker.value.sdCardTotalSize = Number(getsd.msg.full);
      $BTSTracker.value.sdCardUsedSize = Number(getsd.msg.used);
      this.caricamentoSD = false;
      this.updateSD = false;
    }
  }

  async updateSettings() {
    const otaError = await this.sendCommand("get_error", {});
    if (otaError.ok) {
      const s = (otaError.msg as string).split(";");
      $BTSTracker.value.errorOta = s[0];
    }

    const getApn: Record<string, any> = await this.sendCommand("get_apn", {});
    /*if (this.init == 1 && this.simS1.length == 0 && this.simS2.length == 0) {
      this.apnSettings = true;
    } else {*/
    if (this.apnSettings == false) {
      if (getApn.ok && this.staticInfo == false) {
        this.apnSettings = true;
        this.imsi1_old = String(getApn.msg.imsi1);

        this.apn1 = String(getApn.msg.APN1);
        this.userApn1 = String(getApn.msg.uAPN1);
        this.passApn1 = String(getApn.msg.pAPN1);
        this.imsi2_old = String(getApn.msg.imsi2);

        this.apn2 = String(getApn.msg.APN2);
        this.userApn2 = String(getApn.msg.uAPN2);
        this.passApn2 = String(getApn.msg.pAPN2);

        if (this.simS1.length > 0 && this.simS2.length > 0) {
          if (this.apn1.length == 0) {
            this.sceltaSim = "2";
          } else {
            this.sceltaSim = "1";
          }
        } else {
          if (this.simS1.length > 0 && this.apn1.length > 0) {
            this.sceltaSim = "1";
          }
          if (this.simS2.length > 0 && this.apn2.length > 0) {
            this.sceltaSim = "2";
          }
        }

      } else {
        this.apnSettings = true;
      }
    }
    //}
    if (getApn.ok) {
      if (this.init == 1 && this.checkimsi == true && this.atLeastAsim()) {
        if (this.imsi1_old != this.simS1) {
          this.differentSim1 = true;
          createToast({
            title: "Warning, the SIM 1 is different from last time",
            description: "change APN settings",
          }, {
            type: 'warning',
            showIcon: true,
            hideProgressBar: true,
          });
        }
        if (this.imsi2_old != this.simS2) {
          this.differentSim2 = true;
          createToast({
            title: "Warning, the SIM 2 is different from last time",
            description: "change APN settings",
          }, {
            type: 'warning',
            showIcon: true,
            hideProgressBar: true,
          });
        }
        this.checkimsi = false;
      }
    }


    const getFtp: Record<string, any> = await this.sendCommand("get_ftp", {});
    /*if (this.init == 1 && this.simS1.length == 0 && this.simS2.length == 0) {
      this.ftpSettings = true;
    } else {*/
    if (this.ftpSettings == false) {
      if (getFtp.ok && this.staticInfo == false) {
        this.ftpSettings = true;
        this.ftp = String(getFtp.msg.FTP);
        this.userFtp = String(getFtp.msg.uFTP);
        this.passFtp = String(getFtp.msg.pFTP);
        if (this.ftp.length == 0) {
          this.enableapn = false;
        }
      } else {
        this.ftpSettings = true;
      }
    }
    //}
  }

  async getResponceAPN() {
    const getInfo: Record<string, any> = await this.sendCommand("get_responceApn", {});
    if (getInfo.ok) {
      createToast({
        title: "APN settings saved"
      }, {
        type: 'success',
        showIcon: true,
        hideProgressBar: true,
      });
    } else {
      createToast({
        title: "Error saving APN settings",
      }, {
        type: 'danger',
        showIcon: true,
        hideProgressBar: true,
      });
    }
    this.apnSettings = true;
  }

  async getResponceFTP() {
    const getInfo: Record<string, any> = await this.sendCommand("get_responceFtp", {});
    if (getInfo.ok) {
      createToast({
        title: "FTP settings saved",
      }, {
        type: 'success',
        showIcon: true,
        hideProgressBar: true,
      });
    } else {
      createToast({
        title: "Error saving FTP settings",
      }, {
        type: 'danger',
        showIcon: true,
        hideProgressBar: true,
      });
    }
    this.ftpSettings = true;
  }

  public atLeastAsim() {
    if (this.simS1.length > 0 || this.simS2.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  public atLeastAapn() {
    if (this.apn1.length > 0 || this.apn2.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  public async sendCommand(cmd: string, data: Record<string, unknown>): Promise<Record<string, unknown>> {
    if (!this.connected || !this.commandSendChara) throw "Not connected";
    const object = {
      "cmd": cmd,
    } as Record<string, unknown>;
    if (Object.keys(data).length > 0) object["data"] = data; // put the data only if it's not empty

    let rsv: (a: Record<string, unknown>) => void = () => { return; }; // create the resolver
    const prm = new Promise<Record<string, unknown>>((resolve) => rsv = resolve); // create the promise
    const commandObj = new Command(object, rsv); // create the command object
    this.commands.push(commandObj); // add the command to the commands queue
    return prm; // return the promise
  }


  public async disconnect() {
    if ($BTSTracker.value.connected && $BTSTracker.value.server) {
      $BTSTracker.value.server.disconnect();
      $BTSTracker.value.connected = false;
      $BTSTracker.value.connecting = false;
      this.caricamentoSD = true;
    }
  }

  public isConnected(): boolean {
    return $BTSTracker.value.connected;
  }

  public getbatteryPercentage(): number {
    return $BTSTracker.value.mediaBatteria;
  }


  public async downloadFile(fileName: string): Promise<FileBuffer> {
    if (!$BTSTracker.value.connected || !$BTSTracker.value.fileManagerCommandFeedbackChara) {
      createToast(
        {
          title: "Not connected"
        }, {
        type: 'danger',
        showIcon: true,
        hideProgressBar: true,
      }
      ); throw "Not connected";
    }
    if ($BTSTracker.value.fileBuffer || $BTSTracker.value.fileBufferPromiseResolver) {
      createToast(
        {
          title: "A file is already begin downloaded"
        }, {
        type: 'danger',
        showIcon: true,
        hideProgressBar: true,
      }
      );
      throw "A file is already begin downloaded";
    }
    const cmdRsp = await this.sendCommand("download_file", { "file_name": fileName, "folder": this.folder }); // this return the file data, how many parts if exist and so on
    if (cmdRsp.ok) {
      const msg = "" + cmdRsp.msg as string;
      const parts = parseInt(msg);
      //console.log("File parts " + parts);
      const fBuffer = new FileBuffer(fileName, parts);
      $BTSTracker.value.fileBuffer = fBuffer;
      let rsv: ((a: FileBuffer) => void) = () => { return; };
      const prm = new Promise<FileBuffer>((resolve) => rsv = resolve);
      $BTSTracker.value.fileBufferPromiseResolver = rsv;
      await $BTSTracker.value.fileManagerCommandFeedbackChara.writeValue(new Int32Array([0])); // say that we are ready to receive the file
      //console.log("Download file method finished his execution");
      if (parts == 0) {
        if (rsv) {
          rsv($BTSTracker.value.fileBuffer);
          $BTSTracker.value.fileBuffer = undefined;
          $BTSTracker.value.fileBufferPromiseResolver = undefined;
          //console.log("!! FILE DOWNLOADED !!");
        }
      }
      return prm;
    } else {
      throw "Error while downloading a file: " + cmdRsp.msg as string;
    }
  }

  public async getFolders(): Promise<FoldersBuffer> {
    if (!$BTSTracker.value.connected || !$BTSTracker.value.fileManagerCommandFeedbackChara) throw "Not connected";
    if ($BTSTracker.value.fileBuffer || $BTSTracker.value.fileBufferPromiseResolver) throw "A file is already begin downloaded";
    if ($BTSTracker.value.folderBuffer || $BTSTracker.value.folderBufferPromiseResolver) throw "A folder is already begin downloaded";
    
    const cmdResp = await this.sendCommand("get_files", { "path": $BTSTracker.value.folder }); // this return the file data, how many parts if exist and so on
    if (!cmdResp.ok) throw "Error while Fetching files: " + cmdResp.msg as string;
    const msg = "" + cmdResp.msg as string;
    const parts = parseInt(msg);
    //console.log("Folder parts " + parts);
    const fBuffer = new FoldersBuffer(parts);
    $BTSTracker.value.folderBuffer = fBuffer;
    let rsv;
    const prm = new Promise<FoldersBuffer>((resolve) => rsv = resolve);
    $BTSTracker.value.folderBufferPromiseResolver = rsv;
    await this.fileManagerCommandFeedbackChara?.writeValue(new Int32Array([0])); // say that we are ready to receive the file
    $BTSTracker.value.availableAt = new Date().getTime();
    return prm;
  }

  public async getFoldersLoaded(): Promise<FoldersBuffer> {
    if (!$BTSTracker.value.connected || !$BTSTracker.value.fileManagerCommandFeedbackChara) throw "Not connected";
    if ($BTSTracker.value.fileBuffer || $BTSTracker.value.fileBufferPromiseResolver) throw "A file is already begin downloaded";
    if ($BTSTracker.value.folderBuffer || $BTSTracker.value.folderBufferPromiseResolver) throw "A folder is already begin downloaded";
    const cmdResp = await this.sendCommand("get_files", { "path": $BTSTracker.value.folder }); // this return the file data, how many parts if exist and so on
    if (!cmdResp.ok) throw "Error while Fetching files: " + cmdResp.msg as string;
    const msg = "" + cmdResp.msg as string;
    const parts = parseInt(msg);
    //console.log("Folder parts " + parts);
    const fBuffer = new FoldersBuffer(parts);
    $BTSTracker.value.folderBuffer = fBuffer;
    let rsv;
    const prm = new Promise<FoldersBuffer>((resolve) => rsv = resolve);
    $BTSTracker.value.folderBufferPromiseResolver = rsv;
    await $BTSTracker.value.fileManagerCommandFeedbackChara.writeValue(new Int32Array([0])); // say that we are ready to receive the file
    $BTSTracker.value.availableAt = new Date().getTime();
    return prm;
  }


  async handleChecksumFeedback() {
    //console.log("handleChecksumFeedback");
    if (!$BTSTracker.value.connected || !$BTSTracker.value.fileManagerChecksumCharacteristic || !$BTSTracker.value.fileManagerCommandFeedbackChara) throw "Not connected";
    if (!$BTSTracker.value.fileBuffer || !$BTSTracker.value.fileBufferPromiseResolver) throw "No file is being downloaded";
    $BTSTracker.value.fileBuffer.currentChecksum = BTSTracker.stringDecoder.decode($BTSTracker.value.fileManagerChecksumCharacteristic.value);
    //console.log("RECEIVED CHECKSUM --> " + $BTSTracker.value.fileBuffer.currentChecksum);
    await $BTSTracker.value.fileManagerCommandFeedbackChara.writeValueWithResponse(new Int32Array([0])); // say that we received the checksum
  }

  async handleFileManagerReceiver() {
    //console.log("handleFileManagerReceiver");
    if (!$BTSTracker.value.connected || !$BTSTracker.value.fileManagerReceiverChara || !$BTSTracker.value.fileManagerCommandFeedbackChara) throw "Not connected";
    if ($BTSTracker.value.fileBuffer && $BTSTracker.value.fileBufferPromiseResolver) {
      $BTSTracker.value.fileBuffer.addPart($BTSTracker.value.fileManagerReceiverChara.value as DataView);
      $BTSTracker.value.fileBuffer.currentChecksum = "";
      if ($BTSTracker.value.fileBuffer.isComplete()) {
        if ($BTSTracker.value.fileBufferPromiseResolver) {
          $BTSTracker.value.fileBufferPromiseResolver($BTSTracker.value.fileBuffer);
          $BTSTracker.value.fileBuffer = undefined;
          $BTSTracker.value.fileBufferPromiseResolver = undefined;
          //console.log("!! FILE DOWNLOADED !!");
        }
      }
    }

    if ($BTSTracker.value.folderBuffer) {
      const data = BTSTracker.stringDecoder.decode($BTSTracker.value.fileManagerReceiverChara.value);
      $BTSTracker.value.folderBuffer.addPart(data);
      await this.fileManagerCommandFeedbackChara?.writeValue(new Int32Array([0])); // say that we received data and checksum matches
      if ($BTSTracker.value.folderBuffer.isComplete()) {
        if ($BTSTracker.value.folderBufferPromiseResolver) {
          $BTSTracker.value.folderBufferPromiseResolver($BTSTracker.value.folderBuffer);
          $BTSTracker.value.folderBuffer = undefined;
          $BTSTracker.value.folderBufferPromiseResolver = undefined;
          //console.log("!! FOLDER DOWNLOADED !!");
        }
      }
    }
    $BTSTracker.value.availableAt = new Date().getTime();
  }
}

export const $BTSTracker = ref(new BTSTracker());
