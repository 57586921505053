export class Log {
  static info(message: any) {
    //console.lo( "[INFO] %c" + message, " font-family:monospace;");
  }

  static error(message: any) {
    console.error("[ERROR] %c" + message, "color: red; font-family:monospace;");
  }

  static warn(message: any) {
    //console.log(("[WARN] %c" + message, "color: orange; font-family:monospace;");
  }

  static debug(message: any) {
    if (process.env.NODE_ENV !== "production" || document.cookie.includes("**^_^_^_^||scdevmode||^_^_^_^**")) {
      console.debug("[DEBUG] %c" + message, "color: blue; font-family:monospace;");
    }
  }
}