
import { Options, Vue } from "vue-class-component";
import Button from "@/components/Button.vue";
import { ProgressFinisher, useProgress } from "@marcoschulte/vue3-progress";
import { RouteLocationNormalized, NavigationGuardNext } from "vue-router";
import { $BTSTracker, BTSTracker } from "./model";
import router, { routes } from "@/router";
import { isMobile } from "./ts/platform";
import { defineAsyncComponent } from 'vue'
import Connect from './components/Connect.vue'
import { createToast } from "mosha-vue-toastify";
import { isIOS } from "detect-mobile-device";

@Options({
  components: {
    Footer: defineAsyncComponent(() => import("@/components/Footer.vue")),
    Button,
    Connect,
  },
  data() {
    return {
      latitude: 0.0,
      longitude: 0.0,
      accuracy: 0.0,
      speed: 0.0,
      geoLocationError: false,
      btsTracker: $BTSTracker,
      showGpsDialogError: false,
      dialogDimissed: false,
    };
  },
  mounted() {
    this.addTasks();
    this.progressBarStop(); // stoppiamo la progressbar che mostra il caricamento della App
    // avviamo la progressbar durante il cambio della view
    this.$router.beforeEach(
      (
        to: RouteLocationNormalized,
        from: RouteLocationNormalized,
        next: NavigationGuardNext
      ) => {
        this.progressBarStart();
        next();
      }
    );
    // stoppiamo la progressbar quando la view è stata cambiata
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    this.$router.afterEach(
      () => {
        this.progressBarStop();
      }
    );

    const btsTracker = this.btsTracker;
    addEventListener("unload", async () => {
      await btsTracker.disconnect();
    });
    this.$refs["content"].onscroll = () => {
      if (this.$refs["content1"] && this.$refs["content1"].onscroll) {
        this.$refs["content1"].onscroll(this.$refs["content"]);
      }
    };

    setTimeout(() => { // fade out animation of the splash screen

      if (sessionStorage.getItem("updating")) {
        return;
      }

      const preApp = document.getElementById("pre-app");
      const App = document.getElementById("app");
      if (preApp) {
        preApp.style.opacity = "0";
        setTimeout(() => {
          preApp.remove();
        }, 700);
      }
      if (App) App.style.opacity = "1";

    }, 1500);
  },
  create() {
    const progress = useProgress().start(); // avviamo la progressbar mentre carichiamo l'app.
    this.progresses.push(progress);
  },
  beforeMount() {
    window.addEventListener("beforeunload", function (e) {
      e.preventDefault();
      e.returnValue = '';
    })
  }
})


export default class App extends Vue {
  gpsTask: number | undefined;
  latitude = 0.0;
  longitude = 0.0;
  accuracy = 0.0;
  speed: number | null = 0.0;
  geoLocationError = false;
  showGpsDialogError = false;
  dialogDimissed = false;
  progresses = Array<ProgressFinisher>();
  clickCounts = 0;
  btsTracker!: BTSTracker;

  progressBarStart() {
    const progress = useProgress().start();
    this.progresses.push(progress);
  }
  progressBarStop() {
    this.progresses.pop()?.finish();
  }

  getTime(diff: number): string {
    let seconds = Math.floor(diff / (1000)); let minutes = 0;
    if (seconds > 60) {
      seconds = Math.floor(diff / 1000) % 60;
      minutes = Math.floor(diff / (1000 * 60));
    }
    return minutes + "m " + seconds + "s ";
  }

  addTasks(): void {

    //console.log("Injecting GPS Task");
    // task per prendere la posizione
    this.gpsTask = setInterval(() => {
      // task per prendere la posizione
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const today = new Date();
          let day = today.getDate().toString();
          if (day.length == 1) {
            day = '0' + day;
          }
          let month = (today.getMonth() + 1).toString();
          if (month.length == 1) {
            month = '0' + month;
          }
          const date = day + '/' + month + '/' + today.getFullYear();
          let hours = today.getHours().toString();
          if (hours.length == 1) {
            hours = '0' + hours;
          }
          let minutes = (today.getMinutes()).toString();
          if (minutes.length == 1) {
            minutes = '0' + minutes;
          }
          const time = hours + ':' + minutes;
          this.btsTracker.deviceDataTime = date + ' ' + time;
          if (this.btsTracker.isScanning == false && this.btsTracker.staticName == false) {
            this.btsTracker.fileName = "log_" + today.getFullYear() + month + day + "_" + hours + minutes;
          }

          if (this.btsTracker.isScanning) {
            const currentDate = new Date();
            if (this.btsTracker.cellHlte != undefined && this.btsTracker.cellHlte.time != undefined) {
              const diff = currentDate.getTime() - this.btsTracker.cellHlte.time.getTime();
              this.btsTracker.timeLte = this.getTime(diff);
            }
            if (this.btsTracker.cellHgsm != undefined && this.btsTracker.cellHgsm.time != undefined) {
              const diff = currentDate.getTime() - this.btsTracker.cellHgsm.time.getTime();
              this.btsTracker.timeGsm = this.getTime(diff);
            }
            if (this.btsTracker.cellHumts != undefined && this.btsTracker.cellHumts.time != undefined) {
              const diff = currentDate.getTime() - this.btsTracker.cellHumts.time.getTime();
              this.btsTracker.timeUmts = this.getTime(diff);
            }
          }
          this.geoLocationError = false; // altrimenti il dialog di avviso rimane aperto.
          this.latitude = position.coords.latitude;
          this.longitude = position.coords.longitude;
          this.accuracy = position.coords.accuracy;
          this.speed = position.coords.speed;

          this.btsTracker.appLocation = position.coords;
          if (this.btsTracker.gpsBTS == 0) {
            this.btsTracker.tipo = -1;
            this.btsTracker.latitude = this.latitude;
            this.btsTracker.longitude = this.longitude;
            this.btsTracker.accuracy = this.accuracy;
            if (this.btsTracker.checkAccuracy == false && this.btsTracker.isConnected() == true) {
              if (this.btsTracker.accuracy > 15) {
                createToast({
                  title: this.$t('createToast.warning'),
                  description: this.$t('createToast.accuracy'),
                }, {
                  type: 'warning',
                  showIcon: true,
                  hideProgressBar: true,
                });
              }
              this.btsTracker.checkAccuracy = true;
            }
          } else if (this.btsTracker.gpsBTS == 1) {
            this.btsTracker.tipo = 1;
          }
          if (this.btsTracker && this.btsTracker.isConnected() && this.btsTracker.isScanning == true)
            this.btsTracker.sendLocationToBtsTracker(position.coords);

          this.btsTracker.link = "https://google.com/maps/@" + this.latitude + "," + this.longitude + ",20z";

          //console.log(`Location updated: ${this.latitude} , ${this.longitude} (${this.accuracy})`);


          const minutesGSM = Number(this.btsTracker.timeGsm.split('m')[0]);
          const minutesUMTS = Number(this.btsTracker.timeUmts.split('m')[0]);
          const minutesLTE = Number(this.btsTracker.timeLte.split('m')[0]);
          if (this.btsTracker.modem == 1 && this.btsTracker.restart_hua == false) {
            if ((minutesGSM > 3 && minutesUMTS > 3 && minutesLTE > 3) || (minutesGSM > 6 || minutesUMTS > 6 || minutesLTE > 6)) {
              this.btsTracker.sendCommand("restart_hua", { "restart": true });
              //console.log("restart_hua");
              this.btsTracker.restart_hua = true;
            }
          }
        },
        (error) => {
          console.log(error.message);
          if (error.message === "User denied Geolocation") {
            if (this.btsTracker.gpsBTS == 0) {
              this.btsTracker.tipo = 0;
            } else {
              this.btsTracker.tipo = 1;
            }
            const today = new Date();
            let day = today.getDate().toString();
            if (day.length == 1) {
              day = '0' + day;
            }
            let month = (today.getMonth() + 1).toString();
            if (month.length == 1) {
              month = '0' + month;
            }
            const date = day + '/' + month + '/' + today.getFullYear();
            let hours = today.getHours().toString();
            if (hours.length == 1) {
              hours = '0' + hours;
            }
            let minutes = (today.getMinutes()).toString();
            if (minutes.length == 1) {
              minutes = '0' + minutes;
            }
            const time = hours + ':' + minutes;
            this.btsTracker.deviceDataTime = date + ' ' + time;
            createToast({
              title: this.$t("createToast.error"),
              description: this.$t("createToast.geoloDenied"),
            }, {
              type: 'danger',
              showIcon: true,
              hideProgressBar: true,
            });

            if (!this.dialogDimissed) {
              this.showGpsDialogError = true;
              clearInterval(this.gpsTask);
            }

          } else if (error.message === "Timeout expired") {
            /*createToast({
              title: "Error",
              description: "Geolocation Timeout expired",
            }, {
              type: 'danger',
              showIcon: true,
              hideProgressBar: true,
            });*/
            console.log(error.message);
          }
          //console.log(error);
        },
        {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0,
        }
      );
    }, 5000);




  }

  headerClick() {
    if (process.env.NODE_ENV !== 'production' || document.cookie.includes("**^_^_^_^||scdevmode||^_^_^_^**")) {
      if (this.clickCounts >= 5) {
        return;
      }
      this.clickCounts++;
    }
  }


  jumpRoute(jump: number) {
    if (!isMobile()) {
      return;
    }
    let current = 0;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].path === this.$route.path) {
        current = i;
        break;
      }
    }
    current += jump;
    if (current < 0) {
      current = 0;
    } else {
      current %= routes.length;
    }
    this.$router.push(routes[current].path);
  }

  isIOS() {
    return isIOS();
  }

}
