import { ITable, DATA_TYPE, IDataBase, Connection, ISelectQuery } from "jsstore";
import workerInjector from "jsstore/dist/worker_injector";
import { CellRecord, SortingPreferences, Carrier, CarrierCountry } from "./model";
import { RawSortingPreferences } from "./models/SortingPref";

export const connection = new Connection();
const dbname = "data";
connection.addPlugin(workerInjector);
if (process.env.NODE_ENV !== 'production') {
  connection.logStatus = true;
}


const getDatabase = () => {

  const tblCells: ITable = {
    name: 'Cells',
    columns: {
      id: {
        primaryKey: true,
        autoIncrement: true
      },
      date: {
        notNull: true,
        dataType: DATA_TYPE.String
      },

      longitude: {
        notNull: true,
        dataType: DATA_TYPE.Number
      },
      latitude: {
        notNull: true,
        dataType: DATA_TYPE.Number
      },

      speed: {
        notNull: true,
        dataType: DATA_TYPE.Number
      },
      course: {
        notNull: true,
        dataType: DATA_TYPE.Number
      },
      satellites_in_view: {
        notNull: true,
        dataType: DATA_TYPE.Number
      },

      mcc: {
        notNull: true,
        dataType: DATA_TYPE.String
      },
      mnc: {
        notNull: true,
        dataType: DATA_TYPE.String
      },
      lac: {
        notNull: true,
        dataType: DATA_TYPE.Number
      },
      cid: {
        notNull: true,
        dataType: DATA_TYPE.Number
      },
      c1: {
        notNull: true,
        dataType: DATA_TYPE.Number
      },
      c2: {
        notNull: true,
        dataType: DATA_TYPE.Number
      },
      arfcn: {
        notNull: true,
        dataType: DATA_TYPE.Number
      },
      band: {
        notNull: true,
        dataType: DATA_TYPE.String
      },
      ta: {
        notNull: true,
        dataType: DATA_TYPE.Number
      },
      eci: {
        notNull: true,
        dataType: DATA_TYPE.Number
      },
      cell_survey_id: {
        notNull: true,
        dataType: DATA_TYPE.Number
      },
      is_genuine: {
        notNull: true,
        dataType: DATA_TYPE.Boolean
      },
      bsic: {
        notNull: true,
        dataType: DATA_TYPE.Number
      },
      psc: {
        notNull: true,
        dataType: DATA_TYPE.Number
      },
      pci: {
        notNull: true,
        dataType: DATA_TYPE.Number
      },
      tac: {
        notNull: true,
        dataType: DATA_TYPE.Number
      },
      e_node_b: {
        notNull: true,
        dataType: DATA_TYPE.Number
      },
      power: {
        notNull: true,
        dataType: DATA_TYPE.Number
      },
      cell_mode: {
        notNull: true,
        dataType: DATA_TYPE.Number
      },
      tech: {
        notNull: true,
        dataType: DATA_TYPE.Number
      },
      fileName: {
        notNull: true,
        dataType: DATA_TYPE.String
      }
    }

  };

  const tblSortingPreferences: ITable = {
    name: 'sorting_preferences',
    columns: {
      name: {
        primaryKey: true,
        dataType: DATA_TYPE.String,
        unique: true
      },
      sortById: {
        dataType: DATA_TYPE.Number
      },
      ascending: {
        dataType: DATA_TYPE.Boolean
      },
      carriers: {
        dataType: DATA_TYPE.String,
      },
      technologies: {
        dataType: DATA_TYPE.String,
      }
    }
  };

  const tblCarriers: ITable = {
    name: 'carriers',
    columns: {
      id: {
        primaryKey: true,
        autoIncrement: true
      },
      network: {
        dataType: DATA_TYPE.String,
      },
      mcc: {
        dataType: DATA_TYPE.String,
      },
      mnc: {
        dataType: DATA_TYPE.String,
      },
      iso: {
        dataType: DATA_TYPE.String,
      },
      country: {
        dataType: DATA_TYPE.String,
      },
      country_code: {
        dataType: DATA_TYPE.String,
      },
    }
  };

  const tblCarrierCountries: ITable = {
    name: 'carrier_countries',
    columns: {
      id: {
        primaryKey: true,
        autoIncrement: true
      },
      country: {
        dataType: DATA_TYPE.String,
      },
    }
  };

  const dataBase: IDataBase = {
    name: dbname,
    tables: [tblCells, tblSortingPreferences, tblCarriers, tblCarrierCountries]
  };
  return dataBase;
};

export const initDatabase = () => {
  try {
    const dataBase = getDatabase();
    connection.initDb(dataBase);
  } catch (ex) {
    console.error(ex);
  }
};

class Database {
  insertPoints(p: CellRecord[]) {
    return connection.insert({
      into: 'Cells',
      values: p
    });
  }
  insertPoint(p: CellRecord) {
    return connection.insert({
      into: 'Cells',
      values: [p]
    });
  }
  getCellPoints(fileName = ""): Promise<CellRecord[]> {

    const query: ISelectQuery = {
      from: 'Cells',
    }
    if (fileName.length > 0) {
      query.where = {
        fileName: fileName
      }
    }

    const data: Promise<CellRecord[]> = connection.select(query);
    return data;
  }
  clearCellsPoints(fileName: string): Promise<number> {
    return connection.remove({
      from: 'Cells',
      where: {
        fileName: fileName,
      }
    });
  }

  getSortPreferences(tableName: string): Promise<SortingPreferences[]> {
    const query: ISelectQuery = {
      from: 'sorting_preferences',
      where: {
        name: tableName
      },
    }
    const data: Promise<RawSortingPreferences[]> = connection.select(query);
    return data.then((rawData: RawSortingPreferences[]) => {

      const data2: SortingPreferences[] = rawData.map((raw: RawSortingPreferences) => {
        const x = new SortingPreferences(raw.name);
        x.sortById = raw.sortById;
        x.ascending = raw.ascending;
        x.carriers = raw.carriers === "" ? [] : raw.carriers.split(";");
        x.technologies = raw.technologies === "" ? [] : raw.technologies.split(";")
        return x
      });
      return data2;
    });


  }
  insertSortPreferences(p: string) {
    const x = new RawSortingPreferences();
    x.name = p;
    return connection.insert({
      into: 'sorting_preferences',
      values: [x]
    });
  }
  updateSortPreferences(p: SortingPreferences) {
    return connection.update({
      in: 'sorting_preferences',
      where: {
        name: p.name
      },
      set: {
        sortById: p.sortById,
        ascending: p.ascending,
        carriers: p.carriers.join(";"),
        technologies: p.technologies.join(";")
      }
    });
  }

  importCarriers(carriers: Carrier[]) {
    return connection.clear('carriers').then(() => connection.insert({
      into: 'carriers',
      values: carriers
    }));
  }
  getCarriers(): Promise<Carrier[]> {
    const query: ISelectQuery = {
      from: 'carriers',
    }
    const data: Promise<Carrier[]> = connection.select(query);
    return data;
  }

  importCarrierCountries(carrierCountries: CarrierCountry[]) {
    return connection.clear('carrier_countries').then(() => connection.insert({
      into: 'carrier_countries',
      values: carrierCountries
    }));
  }

  getCarrierCountries(): Promise<CarrierCountry[]> {
    const query: ISelectQuery = {
      from: 'carrier_countries',
    }
    const data: Promise<CarrierCountry[]> = connection.select(query);
    return data;
  }
}
export const $database = new Database();